import React from "react"
import Web3Utils from "web3-utils"
import styled from "styled-components";
import * as Web3 from "web3-eth";
import {abi} from "../utils/abi";
import Toast from "./Toast";
import {paymentsAbi} from "../utils/paymentsAbi";
import {rebelPurchasingAbi} from "../utils/rebelPurchasingAbi"

const styles = {
    mint: {
        backgroundColor: '#16181A',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        // background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
    },
    headerText: {
        marginLeft: '10px',
    },
    dashboardInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '15px',
    },
    middleInfo: {
        margin: '0 50px',
        minWidth: '200px',
    },
    miniTitle: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    countdown: {
        fontWeight: '600',
        fontSize: '30px',
        marginBottom: '4px',
        textAlign: 'center',
    },
    miniDescription: {
        color: '#999',
        fontWeight: '12px',
        marginBottom: '15px',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    disabledButton: {
        backgroundColor: '#5b5b5b',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        cursor: 'not-allowed',
        justifyContent: 'center',
    },
    time: {
        fontWeight: '600',
        fontSize: '20px',
    },
    timeWord: {
        fontWeight: '600',
        fontSize: '20px',
        margin: '0 5px',
    },
    rebel: {
        margin: '20px',
    },
    rebelImage: {
        width: '200px',
        height: '200px',
        backgroundColor: '#0b0c0d',
        borderRadius: '10px',
    },
    allInfo: {
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    rebelInfos: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    rebelInfoSide: {

    },
    rebelInfo:{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '15px',
    },
    infoHeader:{
        fontSize: '14px',
        color: '#999',
        marginBottom: '5px',
    },
    infoText: {
        fontSize: '18px',
        fontWeight: '600',
    },
    extraInfoHeader:{
        fontSize: '14px',
        color: '#999',
        marginBottom: '5px',
        textAlign: 'right',
    },
    extraInfoText: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'right',
    },
    rebelImageContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rebIconMed: {
        marginLeft: '8px',
        height: '25px',
    },
}

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

let toastId = 1

class Mint extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toasts: [],
            timeToNextEntry: props.next_drawing?.time_to_next_entry || 0,
            timeToDrawing: props.next_drawing?.time_to_drawing || 0,
            timeToMintClose: props.next_drawing?.time_to_mint_close || 0,
            mintingAllowed: this.props.next_drawing?.minting_allowed,
            whitelisted: this.props.appState.appData?.whitelisted,
            status: this.props.appState.appData?.entry?.status,
            rebels: [1, 2, 3, 4, 5, 6],
            cache: Math.floor(Math.random() * 1000000),
            minted: false,
            checkedMinted: false,
            totalSupply: 0,
            loadedSupply: false,
        }
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    calcSeconds = time => {
        return this.pad(Math.floor(time % 60))
    }

    calcMinutes = time => {
        return this.pad(Math.floor(time/60) % 60)
    }

    calcHours = time => {
        return this.pad(Math.floor(time/3600) % 24)
    }

    calcDays = time => {
        return this.pad(Math.floor(time/86400))
    }

    pad = num => {
        if ((num + "").length === 1) {
            return "0" + num
        }
        return num
    }

    calcTime = time => {
        return (
            <span>
                {Math.floor(time / 86400) >= 1 &&
                    <React.Fragment>
                        <span style={styles.time}>
                            {this.calcDays(time)}
                        </span>
                        <span style={styles.timeWord}>
                            {":"}
                        </span>
                    </React.Fragment>
                }
                {Math.floor(time / 3600) >= 1 &&
                    <React.Fragment>
                            <span style={styles.time}>
                                {this.calcHours(time)}
                            </span>
                        <span style={styles.timeWord}>
                                {":"}
                            </span>
                    </React.Fragment>
                }
                <span style={styles.time}>
                    {this.calcMinutes(time)}
                </span>
                <span style={styles.timeWord}>
                    {":"}
                </span>
                <span style={styles.time}>
                    {this.calcSeconds(time)}
                </span>
            </span>
        )
    }

    enterDrawing() {
        console.log('clicked')
        if (this.state.status === "entered" || !this.props.next_drawing) {
            return
        }

        const body = {drawing_id: this.props.next_drawing?.id}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/entries`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                console.log('Got response from enter drawing', res)
                if (res.success) {
                    this.props.setEnteredDrawing(true)
                    this.state.toasts.push({
                        title: 'Drawing Entered',
                        information: `You have successfully entered the drawing`,
                        id: toastId++
                    })
                    this.setState({toasts: this.state.toasts, status: "entered"})
                } else {

                }
            })
            .catch(err => {

            })
    }

    setTotalSupply = async(web3Instance) => {
        let myContract = new web3Instance.Contract(abi, this.props.appState.contractAddresses.nft_contract_address, { gasLimit: "15000000" })
        let totalSupply = await myContract.methods.balanceOf(this.props.appState.contractAddresses.rebel_purchasing_contract_address).call();
        this.setState({
            totalSupply: totalSupply,
            loadedSupply: true,
        })
    }

    componentDidMount = async() => {
        setInterval(() => this.setState({timeToNextEntry: this.state.timeToNextEntry-1 < 0 ? 0 : this.state.timeToNextEntry-1}), 1000)
        setInterval(() => {
            if (this.state.timeToDrawing - 1 < 0) {
                this.pollForEntryStatus()
            }
            this.setState({timeToDrawing: this.state.timeToDrawing-1 < 0 ? 0 : this.state.timeToDrawing-1})
        }, 1000)
        setInterval(() => this.setState({timeToMintClose: this.state.timeToMintClose-1 < 0 ? 0 : this.state.timeToMintClose-1}), 1000)
        // setTimeout(async() => {
        //     let minted = await this.didMint()
        //     this.setState({minted: minted, checkedMinted: true})
        // }, 10)

        let web3Instance = new Web3('https://polygon-rpc.com/')
        setInterval(this.setTotalSupply.bind(this, web3Instance), 3000)
        // console.log("fetching")
        // let thing = await web3Instance.getTransactionReceipt("0xc7f2e2b304c5634a477c4caac847b6e467233c6859e4067b398f2acf56471b0f")
        // console.log("fetched", thing)
    }


    renderRebels = () => {
        return this.state.rebels.map(this.renderRebel)
    }

    pollForEntryStatus = () => {
        if (this.state.polling || this.state.status !== "entered") {
            return
        }
        let timesToPoll = 100
        this.setState({polling: true})
        let poll = setInterval(() => {
            console.log("polling")
            const body = {drawing_id: this.props.next_drawing?.id}
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/entries/entry_status`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(async(res) => {
                    timesToPoll -= 1
                    console.log('Got response from poll', res)
                    if (res.success) {
                        if (res.status === "lost") {
                            this.setState({status: "lost", polling: false})
                            clearInterval(poll)
                        }
                        if (res.status === "won") {
                            if (res.minting_allowed) {
                                this.setState({status: "won", whitelisted: true, mintingAllowed: true, polling: false})
                                clearInterval(poll)
                            } else {
                                this.setState({status: "won"})
                            }
                        }
                    }

                    if (timesToPoll === 0) {
                        clearInterval(poll)
                    }
                })
                .catch(err => {

                })
        }, 5000)
    }

    renderRebel = (rebel) => {

        const rebelImages = [
            "https://rebellion.s3.us-west-2.amazonaws.com/rebels/staging/mini/1_level1.png",
            "https://rebellion.s3.us-west-2.amazonaws.com/rebels/mini/1_level4.png",
            "https://rebellion.s3.us-west-2.amazonaws.com/rebels/mini/8_level3.png",
            "https://rebellion.s3.us-west-2.amazonaws.com/rebels/mini/56_level4.png",
            "https://rebellion.s3.us-west-2.amazonaws.com/rebels/mini/62_level2.png",
            "https://rebellion.s3.us-west-2.amazonaws.com/rebels/mini/100_level1.png",
        ]

        const multipliers = [
            10, 3, 1, 1.5, 1, 1
        ]

        let image = rebelImages[rebel - 1]

        return (
            <div style={styles.rebel}>
                <div style={styles.rebelImage}>
                    <img width="200px" height="200px" style={{borderRadius: '10px'}} src={image + `?cache=${this.state.cache}`} />
                </div>
                <div style={styles.allInfo}>
                    <div style={styles.rebelInfos}>
                        <div style={styles.rebelInfoSide}>
                            <div style={styles.rebelInfo}>
                                <div style={styles.infoHeader}>
                                    Daily REB
                                </div>
                                <div style={styles.infoText}>
                                    {`${multipliers[rebel-1] * Number(Web3Utils.fromWei(this.props.stats.nft_base_daily_reb, 'ether'))}`}
                                </div>
                            </div>
                        </div>
                        <div style={styles.rebelInfoSide}>
                            <div style={styles.rebelInfo}>
                                <div style={styles.infoHeader}>
                                    Race Multiplier
                                </div>
                                <div style={{...styles.infoText}}>
                                    {`${multipliers[rebel-1]}x`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderRebelNFTs = () => {
        return (
            <div style={styles.rebelImageContainer}>
                {this.renderRebels()}
            </div>
        )
    }

    switchToPolygon = () => {

    }

    capitalize = (word) => {
        const lower = word.toLowerCase();
        return word.charAt(0).toUpperCase() + lower.slice(1);
    }

    didMint = async() => {
        return new Promise(async(resolve, reject) => {
            const web3Instance = new Web3('https://polygon-rpc.com/')
            let myContract = new web3Instance.Contract(paymentsAbi, this.props.appState.contractAddresses.payments_contract_address, { gasLimit: "200000" })
            let didMint = await myContract.methods.didMint(window?.ethereum?.selectedAddress, this.props.next_drawing?.mint_id).call()
            resolve(didMint)
        });
    }

    instantMint = async() => {
        return new Promise(async(resolve, reject) => {
            const web3Instance = new Web3('https://polygon-rpc.com/')
            let myContract = new web3Instance.Contract(rebelPurchasingAbi, this.props.appState.contractAddresses.rebel_purchasing_contract_address, { gasLimit: "200000" })
            let data = myContract.methods.purchaseRebel().encodeABI()

            const transactionParameters = {
                to: this.props.appState.contractAddresses.rebel_purchasing_contract_address,
                from: window?.ethereum?.selectedAddress,
                data: data
            }

            let txHash = null

            try {
                console.log("Sending the transaction now")
                txHash = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                })
                let timesToPoll = 120
                this.setState({polling: true})
                console.log("Polling for successful transaction")
                console.log("tx hash", txHash)
                let poll = setInterval(async() => {
                    timesToPoll -= 1;
                    let receipt = await web3Instance.getTransactionReceipt(txHash)
                    if (receipt && receipt.status) {
                        this.state.toasts.push({
                            title: 'Mint Executed',
                            information: `Your NFT should now be visible on your dashboard`,
                            id: toastId++
                        })
                        this.setState({toasts: this.state.toasts, polling: false, minted: true})

                        clearInterval(poll)
                    } else {
                        timesToPoll -= 1
                        if (timesToPoll <= 0 || receipt?.status === '0x0') {
                            this.state.toasts.push({
                                title: 'Mint Not Found',
                                information: `Try to speed up the transaction or mint again.`,
                                id: toastId++
                            })
                            this.setState({toasts: this.state.toasts, polling: false})
                            clearInterval(poll)
                        }
                    }
                }, 5000)

            } catch (err) {
                console.log('nope')
                console.log(err)
                resolve()
            }

            resolve(txHash)
        })
    }

    mint = async() => {
        return new Promise(async(resolve, reject) => {
            const web3Instance = new Web3('https://polygon-rpc.com/')
            let myContract = new web3Instance.Contract(paymentsAbi, this.props.appState.contractAddresses.payments_contract_address, { gasLimit: "200000" })
            let data = myContract.methods.mint(this.props.next_drawing?.mint_id).encodeABI()

            const transactionParameters = {
                to: this.props.appState.contractAddresses.payments_contract_address,
                from: window?.ethereum?.selectedAddress,
                data: data
            }

            let txHash = null

            try {
                txHash = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                })
                let timesToPoll = 120
                this.setState({polling: true})
                let poll = setInterval(async() => {
                    timesToPoll -= 1;
                    let didMint = await myContract.methods.didMint(window?.ethereum?.selectedAddress, this.props.next_drawing?.mint_id).call()
                    console.log('didMint', didMint)
                    if (didMint > 0) {
                        this.state.toasts.push({
                            title: 'Mint Executed',
                            information: `Your Rebel NFT will be distributed after the minting time has concluded`,
                            id: toastId++
                        })
                        this.setState({toasts: this.state.toasts, polling: false, minted: true})

                        clearInterval(poll)
                    } else {
                        timesToPoll -= 1
                        if (timesToPoll <= 0) {
                            this.state.toasts.push({
                                title: 'Mint Not Found',
                                information: `Try to speed up the transaction or mint again.`,
                                id: toastId++
                            })
                            this.setState({toasts: this.state.toasts, polling: false})
                            clearInterval(poll)
                        }
                    }
                }, 5000)

            } catch (err) {
                console.log('nope')
                console.log(err)
                resolve()
            }

            resolve(txHash)
        })
    }

    requestAllowance = async() => {
        // let currentAllowance = this.props.appState.allowance
        // let needsAllowance = currentAllowance < Number(this.props.next_drawing?.wei || "0")

        // if (!needsAllowance) {
        //     return new Promise(resolve => resolve(true))
        // }

        return new Promise(async(resolve, reject) => {
            const web3Instance = new Web3('https://polygon-rpc.com/')
            let myContract = new web3Instance.Contract(abi, this.props.appState.contractAddresses.token_contract_address, { gasLimit: "200000" })
            let data = myContract.methods.increaseAllowance(this.props.appState.contractAddresses.rebel_purchasing_contract_address, "1000000000000000000000000").encodeABI()

            const transactionParameters = {
                to: this.props.appState.contractAddresses.token_contract_address,
                from: window?.ethereum?.selectedAddress,
                data: data
            }

            let txHash = null

            try {
                txHash = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                })
                this.setState({polling: true})
                let poll = setInterval(async() => {
                    let allowance = await myContract.methods.allowance(window?.ethereum?.selectedAddress, this.props.appState.contractAddresses.rebel_purchasing_contract_address).call()
                    console.log('allowance', allowance)
                    if (allowance > 0) {
                        this.setState({polling: false})
                        this.props.setPurchasingAllowance(Number(allowance))
                        clearInterval(poll)
                    }
                }, 2000)
            } catch (err) {
                console.log('nope')
                console.log(err)
            }
            resolve(txHash)
        })
    }

    renderMint = () => {
        let balance = Number(this.props.appState.balance)
        let cost = Number(Web3Utils.fromWei(("5000000000000000000000" || "0"), 'ether'))

        let currentAllowance = this.props.appState.purchasing_allowance
        let needsAllowance = currentAllowance < Number("5000000000000000000000")

        let canPurchase = true
        let canMint = false
        let errorMessage = ''

        if (balance < cost) {
            canPurchase = false
            errorMessage = 'Insufficient REB'
        }

        if (canPurchase) {
            canMint = true
        }

        let needSwitch = !this.props.appState.onPolygon

        if (this.state.polling) {
            return (
                <div style={{...styles.disabledButton, fontSize: '20px'}}>
                    Processing...
                </div>
            )
        }

        if (needSwitch) {
            return (
                <PrimaryButton onClick={this.props.appState.switchToPolygon.bind(this)} style={{...styles.primaryButton, fontSize: '20px'}}>
                    Switch To Polygon
                </PrimaryButton>
            )
        }

        if (parseInt(this.state.totalSupply) === 0) {
            canMint = false;
            if (this.state.loadedSupply) {
                errorMessage = "No Supply"
            } else {
                errorMessage = "Checking Supply"
            }
        }

        if (this.props.appState.timeToInstantMint > 0) {
            canMint = false;
            errorMessage = this.calcTime(this.props.appState.timeToInstantMint)
        }

        if (this.state.minted) {
            return (
                <div style={{...styles.disabledButton, fontSize: '20px'}}>
                    {'Minted'}
                </div>
            )
        }

        if (needsAllowance && canMint) {
            return (
                <PrimaryButton onClick={this.requestAllowance.bind(this)} style={{...styles.primaryButton}}>
                    Approve REB
                </PrimaryButton>
            )
        }

        if (canMint) {
            return (
                <PrimaryButton onClick={this.instantMint.bind(this)} style={{...styles.primaryButton, fontSize: '20px'}}>
                    Mint
                </PrimaryButton>
            )
        }

        return (
            <div style={{...styles.disabledButton, fontSize: '20px'}}>
                {errorMessage}
            </div>
        )
    }

    renderPurchase = () => {
        let balance = Number(this.props.appState.balance)
        let cost = Number(Web3Utils.fromWei((this.props.next_drawing?.wei || "0"), 'ether'))

        let currentAllowance = this.props.appState.purchasing_allowance
        let needsAllowance = currentAllowance < Number(this.props.next_drawing?.wei || "0")


        let canPurchase = true
        let canMint = false
        let errorMessage = ''

        if (this.state.timeToDrawing <= 0) {
            canPurchase = false
            errorMessage = "Drawing Closed"
        }

        if (balance < cost) {
            canPurchase = false
            errorMessage = 'Insufficient REB'
        }

        if (this.state.status === "entered") {
            canPurchase = false
            errorMessage = 'Entered'
        }

        if (this.state.timeToDrawing <= 0 && this.state.status === "lost") {
            canPurchase = false
            errorMessage = 'Lost Drawing'
        }

        if (this.state.timeToDrawing <= 0 && this.state.status === "entered") {
            canPurchase = false
            errorMessage = 'Deciding Winners...'
        }

        if (this.state.timeToDrawing <= 0 && this.state.status === "won" && !this.state.mintingAllowed) {
            canPurchase = false
            errorMessage = 'Adding To Whitelist...'
        }

        if (this.props.next_drawing.mint_status === "complete") {
            canPurchase = false
            errorMessage = 'Drawing Closed'
        }

        if (this.state.mintingAllowed && this.state.status !== "won" && this.state.status !== "lost" && this.state.checkedMinted) {
            canPurchase = false
            errorMessage = "Entries Closed"
        }

        if (this.state.timeToMintClose >= 0 && this.state.status === "won" && this.state.mintingAllowed && this.state.checkedMinted) {
            canMint = true
        }

        if (this.state.timeToNextEntry > 0) {
            canPurchase = false
            errorMessage = 'Coming Soon'
        }

        if (!this.props.next_drawing) {
            canPurchase = false
            errorMessage = 'No Upcoming Drawing'
        }

        let needSwitch = !this.props.appState.onPolygon

        if (this.state.polling) {
            return (
                <div style={{...styles.disabledButton, fontSize: '20px'}}>
                    Processing...
                </div>
            )
        }

        if (needSwitch) {
            return (
                <PrimaryButton onClick={this.props.appState.switchToPolygon.bind(this)} style={{...styles.primaryButton, fontSize: '20px'}}>
                    Switch To Polygon
                </PrimaryButton>
            )
        }

        if (this.state.minted) {
            return (
                <div style={{...styles.disabledButton, fontSize: '20px'}}>
                    {'Minted'}
                </div>
            )
        }

        if (needsAllowance && canMint) {
            return (
                <PrimaryButton onClick={this.requestAllowance.bind(this)} style={{...styles.primaryButton}}>
                    Approve REB
                </PrimaryButton>
            )
        }

        if (canMint) {
            return (
                <PrimaryButton onClick={this.mint.bind(this)} style={{...styles.primaryButton, fontSize: '20px'}}>
                    Mint
                </PrimaryButton>
            )
        }

        if (canPurchase) {
            return (
                <PrimaryButton onClick={this.enterDrawing.bind(this)} style={{...styles.primaryButton, fontSize: '20px'}}>
                    {this.props.appState.appData.current_citizen?.whitelist_spots > 0 ? 'Use Whitelist' : 'Enter'}
                </PrimaryButton>
            )
        }

        return (
            <div style={{...styles.disabledButton, fontSize: '20px'}}>
                {errorMessage}
            </div>
        )
    }

    renderUpcoming = () => {
        if (this.state.timeToNextEntry > 0) {
            return (
                <div>
                    <div style={styles.miniTitle}>
                        Next Mint In
                    </div>
                    <div style={styles.miniDescription}>
                        {`${this.props.next_drawing?.nfts_available || "0"} Rebels Available`}
                    </div>
                    <div style={styles.countdown}>
                        {this.calcTime(this.state.timeToNextEntry)}
                    </div>
                </div>
            )
        }

        if (this.state.timeToDrawing > 0) {
            return (
                <div>
                    <div style={styles.miniTitle}>
                        {this.state.mintingAllowed ? "Mint Time Remaining" : "Entry Time Remaining"}
                    </div>
                    <div style={styles.miniDescription}>
                        {`${this.props.next_drawing?.nfts_available || "0"} Rebels Available`}
                    </div>
                    <div style={styles.countdown}>
                        {this.calcTime(this.state.timeToDrawing)}
                    </div>
                </div>
            )
        }
        if (this.state.timeToMintClose > 0) {
            return (
                <div>
                    <div style={styles.miniTitle}>
                        Mint Time Remaining
                    </div>
                    <div style={styles.miniDescription}>
                        {`${this.props.next_drawing?.nfts_available || "0"} Rebels Available`}
                    </div>
                    <div style={styles.countdown}>
                        {this.calcTime(this.state.timeToMintClose)}
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div style={styles.miniTitle}>
                    Mint Status
                </div>
                <div style={styles.miniDescription}>
                    {`${this.props.next_drawing?.nfts_available || "0"} Rebels Available`}
                </div>
                <div style={styles.countdown}>
                    Complete
                </div>
            </div>
        )
    }

    renderInstantMint = () => {
        // if (this.props.appState.appData.current_citizen?.role !== 'admin') {
        //     return null
        // }

        return (
            <div style={styles.panel}>
                <div style={styles.headerContainer}>
                    <div>
                        <div style={styles.header}>
                            <i className="fas fa-plus-circle" style={styles.navIcon} />
                            <span style={styles.headerText}>
                                    Mint A Rebel
                                </span>
                        </div>
                        <div style={{...styles.description, maxWidth: '600px'}}>
                            {`5000 REB required to mint a Rebel NFT`}
                        </div>
                    </div>
                </div>
                <div style={{...styles.dashboardInfo, flexDirection: this.props.appState.mobile ? 'column' : 'row',}}>
                    <div>
                        <div style={styles.miniTitle}>
                            {"Mint"}
                        </div>
                        <div style={styles.miniDescription}>
                            {`Click below to mint a Rebel NFT`}
                        </div>
                        {this.renderMint()}
                    </div>
                    <div style={{...styles.middleInfo, margin: this.props.appState.mobile ? '40px 0' : '0'}}>
                        <div style={styles.miniTitle}>
                            Mint Cost
                        </div>
                        <div style={styles.miniDescription}>
                            {`${Number(Web3Utils.fromWei("5000000000000000000000", 'ether'))} REB - ($${(this.props.stats.reb_price * Number(Web3Utils.fromWei("5000000000000000000000" || "0", 'ether'))).toFixed(2)}) REB`}
                        </div>
                        <a target={"_blank"} href={"https://docs.rebelliondao.com/how-to/how-to-buy-reb"}>
                            <PrimaryButton style={{...styles.primaryButton, fontSize: '20px'}}>
                                Acquire
                                <span>
                                    <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                </span>
                            </PrimaryButton>
                        </a>
                    </div>
                    <div>
                        <div style={styles.miniTitle}>
                            NFTs Remaining
                        </div>
                        <div style={styles.miniDescription}>
                            {`NFTs available`}
                        </div>
                        <div style={{...styles.miniTitle}}>
                            {`${this.state.totalSupply} Remaining`}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMintARebel = () => {
        if (!this.props.next_drawing) {
            return null
        }

        // if (this.props.appState.appData.current_citizen?.role !== 'admin') {
        //     return null
        // }

        return (
            <div style={styles.panel}>
                {/*fas fa-plus-circle*/}
                <div style={styles.headerContainer}>
                    <div>
                        <div style={styles.header}>
                            <i className="fas fa-plus-circle" style={styles.navIcon} />
                            <span style={styles.headerText}>
                                    Mint A Rebel
                                </span>
                        </div>
                        <div style={{...styles.description, maxWidth: '600px'}}>
                            {`Drawing lasts 15 minutes before the mint. Please make sure your wallet contains ${Number(Web3Utils.fromWei(this.props.next_drawing?.wei || "0", 'ether'))} REB when entering the drawing, if your REB is transferred out before the end of the drawing you will automatically be disqualified.`}
                        </div>
                    </div>
                    <div>
                        <div style={styles.rebelInfo}>
                            <div style={styles.extraInfoHeader}>
                                Whitelist Spots
                            </div>
                            <div style={styles.extraInfoText}>
                                {this.props.appState.appData.current_citizen.whitelist_spots}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{...styles.dashboardInfo, flexDirection: this.props.appState.mobile ? 'column' : 'row',}}>
                    <div>
                        <div style={styles.miniTitle}>
                            {this.state.mintingAllowed ? "Mint" : "Enter Drawing"}
                        </div>
                        <div style={styles.miniDescription}>
                            {`${this.props.drawing_entrants || 0} Entrants - ${this.props.next_drawing?.nfts_available || 0} Rebels Available`}
                        </div>
                        {this.renderPurchase()}

                    </div>
                    <div style={{...styles.middleInfo, margin: this.props.appState.mobile ? '40px 0' : '0'}}>
                        <div style={styles.miniTitle}>
                            Mint Cost
                        </div>
                        <div style={styles.miniDescription}>
                            {`${Number(Web3Utils.fromWei(this.props.next_drawing?.wei || "0", 'ether'))} REB - ($${(this.props.stats.reb_price * Number(Web3Utils.fromWei(this.props.next_drawing?.wei || "0", 'ether'))).toFixed(2)})`}
                        </div>
                        <a target={"_blank"} href={"https://docs.rebelliondao.com/how-to/how-to-buy-reb"}>
                            <PrimaryButton style={{...styles.primaryButton, fontSize: '20px'}}>
                                Acquire
                                <span>
                                        <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                    </span>
                            </PrimaryButton>
                        </a>
                    </div>
                    {this.renderUpcoming()}
                </div>
            </div>
        )
    }

    render () {
        return (
            <div style={styles.mint}>
                {this.renderInstantMint()}
                {/*{this.renderMintARebel()}*/}
                {/*<div style={styles.panel}>*/}
                {/*    <div style={styles.header}>*/}
                {/*        <i className="fas fa-plus-square" style={styles.navIcon} />*/}
                {/*        <span style={styles.headerText}>*/}
                {/*            Mint Status*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div style={{...styles.description, maxWidth: '800px'}}>*/}
                {/*        When the drawing & minting process begins, relevant information will be displayed below.*/}
                {/*    </div>*/}
                {/*    <div style={{...styles.dashboardInfo, flexDirection: this.props.appState.mobile ? 'column' : 'row', textAlign:  this.props.appState.mobile ? 'center' : 'left'}}>*/}
                {/*        <div>*/}
                {/*            <div style={styles.miniTitle}>*/}
                {/*                Drawing Status*/}
                {/*            </div>*/}
                {/*            <div style={styles.miniDescription}>*/}
                {/*                Winners have 30 minutes to mint*/}
                {/*            </div>*/}
                {/*            <div style={{...styles.miniTitle, color: this.state.status === "entered" ? '#00b3ff' : '#fc3903'}}>*/}
                {/*                {this.props.appState.enteredDrawing ? 'Entered' : 'Not Entered'}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div style={{...styles.middleInfo, margin: this.props.appState.mobile ? '40px 0' : '0'}}>*/}
                {/*            <div style={styles.miniTitle}>*/}
                {/*                Benefits*/}
                {/*            </div>*/}
                {/*            <div style={styles.miniDescription}>*/}
                {/*                Sponsoring Required*/}
                {/*            </div>*/}
                {/*            <div style={{...styles.miniTitle}}>*/}
                {/*                {`${Number(Web3Utils.fromWei( this.props.stats.nft_base_daily_reb, 'ether')).toFixed(2)}+ REB Daily`}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <div style={styles.miniTitle}>*/}
                {/*                Whitelist Status*/}
                {/*            </div>*/}
                {/*            <div style={styles.miniDescription}>*/}
                {/*                Rank up to get a guaranteed mint*/}
                {/*            </div>*/}
                {/*            {this.props.appState.appData.current_citizen.whitelist_spots > 0 ? (*/}
                {/*                <div style={{...styles.miniTitle, color: '#00b3ff'}}>*/}
                {/*                    Whitelisted*/}
                {/*                </div>*/}
                {/*            ) : (*/}
                {/*                <PrimaryButton onClick={this.props.setPage.bind(this, 'rankings')} style={{...styles.primaryButton, fontSize: '20px'}}>*/}
                {/*                    Get Whitelisted*/}
                {/*                </PrimaryButton>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div style={styles.panel}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-fire" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    {`Rebel NFTs ${this.props.next_drawing ? `- ${this.capitalize(this.props.next_drawing?.mint_id)}` : ''}`}
                                </span>
                            </div>
                            <div style={{...styles.description, maxWidth: '800px'}}>
                                {this.props.next_drawing ? `${this.capitalize(this.props.next_drawing?.mint_id || '')} Collection - ${this.props.next_drawing?.nfts_available || 0} NFTs` : 'Previous Rebel Nfts'}
                            </div>
                        </div>
                        <div>
                            <div style={styles.extraInfo}>
                                <div style={styles.extraInfoHeader}>
                                    Daily REB Generated
                                </div>
                                <div style={styles.extraInfoText}>
                                    {`+${Number(Web3Utils.fromWei( this.props.stats.nft_base_daily_reb, 'ether')).toFixed(2)} REB`}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRebelNFTs()}
                    {this.renderToasts()}
                </div>
            </div>
        );
    }
}

export default Mint
