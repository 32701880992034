import React from "react"

const styles = {
    greetingFont: {
        fontFamily: 'Lato, Arial',
        fontSize: '24px',
        width: '100%',
        height: '100vh',
        color: 'white',
        boxSizing: 'border-box',
        background: 'linear-gradient(317deg, rgba(131,103,95,1) 0%, rgba(69,60,57,1) 19%, rgba(31,27,26,1) 100%)',
    },
    imageStyle: {
        objectFit: 'cover',
        height: '50px',
    },
    rebellionText: {
        marginLeft: '15px',
        fontWeight: '600',
    },
    daoText: {
        fontSize: '14px',
        marginLeft: '15px',
        color: '#ff9800',
    },
    innerWindow: {
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    navButtons: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
    },
    navButton: {
        margin: '10px 20px',
        fontSize: '14px',
        display: 'flex',
    },
    navSecondary: {
        border: '1px solid #FFF',
        borderRadius: '20px',
        padding: '15px',
        fontSize: '14px',
    },
    tokenIcon: {
        objectFit: 'cover',
        height: '20px',
        marginLeft: '5px',
    },
    mainContent: {
        marginTop: '70px',
        width: '100%',
        padding: '50px 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftContent: {
        flexBasis: '50%',
        padding: '50px',
    },
    primaryText: {
        fontSize: '60px',
        fontWeight: '600',
    },
    secondaryText: {
        fontSize: '24px',
        marginTop: '50px',
        color: '#a39b99',
    },
    buttons: {
        display: 'flex',
        marginTop: '50px',
    },
    buttonSecondary: {
        border: '1px solid #FFF',
        borderRadius: '30px',
        padding: '20px',
        fontSize: '20px',
    },
    buttonPrimary: {
        padding: '20px',
        backgroundColor: '#ff9800',
        borderRadius: '30px',
        fontSize: '20px',
        fontWeight: '600',
        marginLeft: '50px',
        color: '#262423',
    },
    rightContent: {
        flexBasis: '50%',
        padding: '10px 50px 50px',
        display: 'flex',
        justifyContent: 'center',
    },
    primaryImage: {
        objectFit: 'cover',
        height: '500px',
    },
    bottomInfo: {
        borderRadius: '30px',
        boxSizing: 'border-box',
        padding: '30px',
        margin: '20px',
        alignSelf: 'flex-end',
        display: 'flex',
        backgroundColor: 'rgba(0,0,0,.1)',
    },
    statSection: {
        marginRight: '80px',
    },
    statTitle: {
        fontSize: '14px',
    },
    statInfo: {
        fontWeight: '600',
        fontSize: '20px',
        marginTop: '5px',
    }
}

class Welcome extends React.Component {
    render () {
        return (
            <div style={styles.greetingFont}>
                <div style={styles.header}>
                    <div style={styles.innerWindow}>
                        <img style={styles.imageStyle} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                        <div>
                            <div style={styles.rebellionText}>
                                {`Rebellion`}
                            </div>
                            <div style={styles.daoText}>
                                {`DAO`}
                            </div>
                        </div>
                    </div>
                    <div style={styles.navButtons}>
                        <div style={styles.navButton}>
                            Buy
                        </div>
                        <div style={styles.navButton}>
                            Docs
                        </div>
                        <div style={styles.navButton}>
                            Community
                        </div>
                        <div style={styles.navSecondary}>
                            Become a Citizen
                        </div>
                    </div>
                </div>
                <div style={styles.mainContent}>
                    <div style={styles.leftContent}>
                        <div style={styles.primaryText}>
                            Join the Rebellion DAO Experiment
                        </div>
                        <div style={styles.secondaryText}>
                            Become a Rebellion Citizen to start earning 100 REB tokens daily and vote on the future of the Rebellion DAO.
                        </div>
                        <div style={styles.buttons}>
                            <div style={styles.buttonSecondary}>
                                Learn More
                            </div>
                            <div style={styles.buttonPrimary}>
                                Enter App
                            </div>
                        </div>
                    </div>
                    <div style={styles.rightContent}>
                        {/*<img style={styles.primaryImage} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}

                    </div>
                </div>
                <div style={styles.bottomInfo}>
                    <div style={styles.statSection}>
                        <div style={styles.statTitle}>
                            Citizens
                        </div>
                        <div style={styles.statInfo}>
                            103,230
                        </div>
                    </div>
                    <div style={styles.statSection}>
                        <div style={styles.statTitle}>
                            REB Tokens
                        </div>
                        <div style={styles.statInfo}>
                            502,230,234
                        </div>
                    </div>
                    <div style={styles.statSection}>
                        <div style={styles.statTitle}>
                            Market Cap
                        </div>
                        <div style={styles.statInfo}>
                            $252,234,589
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Welcome
