import React from "react"
import { abi } from '../utils/abi';
import * as Web3 from "web3-eth";
import Web3Utils from "web3-utils";

const styles = {
    stats: {
        backgroundColor: '#16181A',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        // background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
    },
    headerText: {
        marginLeft: '10px',
    },
    dashboardInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '15px',
    },
    middleInfo: {
        margin: '0 50px',
        minWidth: '200px',
    },
    miniTitle: {
        color: '#999',
        fontWeight: '600',
        fontSize: '14px',
        marginBottom: '8px',
    },
    stat: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    countdown: {
        fontWeight: '600',
        fontSize: '30px',
        marginBottom: '4px',
    },
    miniDescription: {
        color: '#999',
        fontWeight: '12px',
        marginBottom: '15px',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
    },
}

class Stats extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            totalSupply: 0,
            treasurySupply: 0
        }
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    componentDidMount = async() => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let myContract = new web3Instance.Contract(abi, this.props.appState.contractAddresses.token_contract_address, { gasLimit: "15000000" })
        let totalSupply = await myContract.methods.totalSupply().call();
        totalSupply = Number(Web3Utils.fromWei(totalSupply, 'ether')).toFixed(2)
        let treasurySupply = await myContract.methods.balanceOf(this.props.appState.contractAddresses.treasury_address).call();
        treasurySupply = Number(Web3Utils.fromWei(treasurySupply, 'ether')).toFixed(2)
        this.setState({
            totalSupply: totalSupply,
            treasurySupply: treasurySupply
        })
    }

    render () {
        return (
            <div style={styles.stats}>
                <div style={styles.panel}>
                    <div style={styles.header}>
                        <i className="fas fa-chart-line" style={styles.navIcon} />
                        <span style={styles.headerText}>
                            Stats
                        </span>
                    </div>
                    <div style={{...styles.description, maxWidth: '800px'}}>
                        These stats for the Rebellion DAO are updated every 5 minutes. Check this page to see the daily growth of the protocol.
                    </div>
                    <div style={styles.dashboardInfo}>
                        <div>
                            <div style={styles.miniTitle}>
                                REB In Circulation
                            </div>
                            <div style={{...styles.stat}}>
                                {this.numberWithCommas(this.state.totalSupply)}
                            </div>
                            <div style={{...styles.miniTitle, marginTop: '40px'}}>
                                Daily Income
                            </div>
                            <div style={{...styles.stat}}>
                                {Number(Web3Utils.fromWei(this.props.stats.base_daily_reb, 'ether')).toFixed(2) + ' REB'}
                            </div>
                        </div>
                        <div style={styles.middleInfo}>
                            <div style={styles.miniTitle}>
                                Rebellion Citizens
                            </div>
                            <div style={{...styles.stat}}>
                                {this.props.appState.appData.citizens_amount}
                            </div>
                            <div style={{...styles.miniTitle, marginTop: '40px'}}>
                                Market Cap
                            </div>
                            <div style={{...styles.stat}}>
                                {'$' + this.numberWithCommas((parseFloat(this.props.stats.reb_price) * parseFloat(this.state.totalSupply)).toFixed(2))}
                            </div>
                        </div>
                        <div>
                            <div style={styles.miniTitle}>
                                Price per REB
                            </div>
                            <div style={{...styles.stat}}>
                                {'$' + this.props.stats.reb_price}
                            </div>
                            <div style={{...styles.miniTitle, marginTop: '40px'}}>
                                REB in treasury
                            </div>
                            <div style={{...styles.stat}}>
                                {this.numberWithCommas(this.state.treasurySupply)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Stats
