import React from "react"
import { abi } from '../utils/abi';
import * as Web3 from "web3-eth";
import Web3Utils from "web3-utils";
import styled from "styled-components";

const styles = {
    stats: {
        backgroundColor: '#16181A',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        // background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px',
        boxSizing: 'border-box',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
    },
    headerText: {
        marginLeft: '10px',
    },
    dashboardInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '15px',
    },
    middleInfo: {
        margin: '0 50px',
        minWidth: '200px',
    },
    miniTitle: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    stat: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    countdown: {
        fontWeight: '600',
        fontSize: '30px',
        marginBottom: '4px',
    },
    miniDescription: {
        color: '#999',
        fontWeight: '12px',
        marginBottom: '15px',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
    },






    info: {
        backgroundColor: '#16181A',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '20px',
        alignItems: 'center',
    },
    container: {
        maxWidth: '1000px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: '50px',
        marginBottom: '50px',
        borderBottom: '1px solid #333'
    },
    title: {
        color: 'white',
        fontWeight: '600',
        fontSize: '36px',
        textAlign: 'center',
        marginBottom: '30px',
    },
    howItWorksContent: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '30px',
    },
    dividedContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#17191a',
        borderRadius: '20px',
        marginTop: '20px',
        boxSizing: 'border-box',
        padding: '20px',
        alignItems: 'center',
        flexBasis: '50%',
        textAlign: 'center',
    },
    contentTitle: {
        fontWeight: '600',
        fontSize: '18px',
        marginBottom: '20px',
    },
    addressCard: {
        fontSize: '150px',
        color: '#999',
    },
    icon: {
        fontSize: '30px',
        color: '#00b3ff',
    },
    addressCardContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
    },
    whatContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '30px',
    },
    orderedList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
        flexShrink: 0,
    },
    listItemMini: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
        flexShrink: 0,
    },
    listItemNumber: {
        backgroundColor: '#17191a',
        flexShrink: 0,
        borderRadius: '60px',
        fontWeight: '600',
        fontSize: '24px',
        width: '60px',
        height: '60px',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: '20px',
    },
    listItemNumberMini: {
        backgroundColor: '#1e2022',
        flexShrink: 0,
        borderRadius: '60px',
        fontWeight: '600',
        fontSize: '24px',
        width: '60px',
        height: '60px',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: '20px',
    },
    listItemContent: {
        fontSize: '18px',
        color: '#CCC',
    },
    listItemContentMini: {
        textAlign: 'left',
        fontSize: '16px',
        color: '#CCC',
    },
    citizenshipSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '30px',
    },
    citizenshipSubsection: {
        backgroundColor: '#17191a',
        borderRadius: '20px',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexBasis: '50%',
    },
    nftContainer: {
        display: 'flex',
    },
    secondaryButton: {
        marginTop: '15px',
        padding: '10px',
        display: 'inline-flex',
        border: '1px solid #999',
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        color: '#999',
    },
    roadmapSection: {
        display: 'flex',
        width: '100%',
        marginTop: '30px',
    },
    roadmapItem: {
        backgroundColor: '#17191a',
        borderRadius: '20px',
        padding: '20px',
        boxSizing: 'border-box',
        flexBasis: '33%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    roadmapTitle: {
        fontSize: '22px',
        fontWeight: '600',
    },
    roadmapImage: {
        flexGrow: 1,
    },
    roadmapInfo: {
        fontSize: '16px',
        color: '#999',
    },
    roadmapIcon: {
        fontSize: '80px',
        color: '#FFF',
        margin: '20px 0 40px'
    },
    statItem: {
        backgroundColor: '#17191a',
        borderRadius: '20px',
        padding: '20px',
        flexGrow: 1,
        margin: '10px 0 0',
        boxSizing: 'border-box',
    },
    statTitle: {
        color: '#FFF',
        fontWeight: '600',
        fontSize: '16px',
        marginTop: '8px',
    },
    buttons: {
        alignSelf: 'center',
        display: 'flex',
    },
    navIcon: {
        marginRight: '15px',
        fontSize: '24px',
    },
    disabledButton: {
        backgroundColor: '#5b5b5b',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        cursor: 'not-allowed',
        justifyContent: 'center',
    },
}

const SecondaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #333 !important;
}
`

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

class Airdrop extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            cache: Math.floor(Math.random() * 1000000),
            totalSupply: 0,
            clickedDiscord: false
        }
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    componentDidMount = async() => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let myContract = new web3Instance.Contract(abi, this.props.appState.contractAddresses.token_contract_address, { gasLimit: "15000000" })
        let totalSupply = await myContract.methods.totalSupply().call();
        totalSupply = Number(Web3Utils.fromWei(totalSupply, 'ether')).toFixed(2)
        this.setState({
            totalSupply: totalSupply,
        })
    }

    renderClaimButton = () => {
        if (this.state.clickedDiscord) {
            return (
                <PrimaryButton onClick={() => {}} style={{...styles.primaryButton, fontSize: '20px'}}>
                    Claim 5000 $REB
                </PrimaryButton>
            )
        } else {
            return (
                <div style={{...styles.disabledButton, fontSize: '20px'}}>
                    Claim 5000 $REB
                </div>
            )
        }
    }

    handleClick = (e) => {
        this.setState({clickedDiscord: true})
    }

    render () {
        return (
            <div style={styles.stats}>
                <div style={styles.panel}>
                    <div style={styles.header}>
                        <i className="fas fa-parachute-box" style={styles.navIcon} />
                        <span style={styles.headerText}>
                            Airdrop
                        </span>
                    </div>
                    <div style={{...styles.description, maxWidth: '800px'}}>
                        DG Wearable holders are eligible for a 500 $REB airdrop per wearable. $REB can be exchanged on quickswap, used to buy Rebel NFTs, or used to buy Citizenship.
                    </div>
                    <div style={{...styles.dashboardInfo, flexDirection: this.props.appState.mobile ? 'column' : 'row',}}>
                        <div style={{...styles.middleInfo, margin: this.props.appState.mobile ? '40px 0' : '0'}}>
                            <div style={styles.miniTitle}>
                                Wearables Owned
                            </div>
                            <div style={styles.miniDescription}>
                                Snapshot taken on March 10th
                            </div>
                            <div style={{...styles.miniTitle}}>
                                {10}
                            </div>
                        </div>
                        <div>
                            <div style={styles.miniTitle}>
                                Step 1
                            </div>
                            <div style={styles.miniDescription}>
                                Check out the project.
                            </div>
                            <a onClick={this.handleClick.bind(this)}  target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                                <PrimaryButton style={{...styles.primaryButton, fontSize: '20px'}}>
                                    Join Discord
                                </PrimaryButton>
                            </a>
                        </div>
                        <div>
                            <div style={styles.miniTitle}>
                                Claim REB
                            </div>
                            <div style={styles.miniDescription}>
                                Claim enabled after joining Discord
                            </div>
                            {this.renderClaimButton()}
                        </div>
                    </div>
                </div>


                <div style={{...styles.panel, padding: this.props.appState.mobile ? '40px 10px' : '50px'}}>
                    <div style={styles.section}>
                        <div style={styles.title}>
                            What is Rebellion?
                        </div>
                        <div style={{...styles.description, fontSize: '20px', textAlign: 'center', color: '#CCC', marginTop: '0'}}>
                            Rebellion bridges NFTs and finance. Rebel NFTs generate in excess of $200+ per year in passive income via their utility in a similar way to how DG Wearables generate ICE.
                        </div>
                        <div style={styles.buttons}>
                            <a target={"_blank"} href={'https://twitter.com/RebellionBase'}>
                                <SecondaryButton style={{...styles.secondaryButton, fontSize: '20px', marginRight: '10px', marginTop: '0px'}}>
                                    <i className="fab fa-twitter" style={styles.navIcon} />
                                    {` Follow Twitter`}
                                </SecondaryButton>
                            </a>
                            <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                                <PrimaryButton style={{...styles.primaryButton, fontSize: '20px', marginLeft: '10px'}}>
                                    <i className="fab fa-discord" style={styles.navIcon} />
                                    {` Join Discord`}
                                </PrimaryButton>
                            </a>
                        </div>
                    </div>
                    <div style={styles.section}>
                        <div style={styles.title}>
                            How does it work?
                        </div>
                        <div style={styles.miniDescription}>
                            The Rebellion DAO generates demand for the REB token via the following
                        </div>
                        <div style={{...styles.howItWorksContent, flexWrap: this.props.appState.mobile ? 'wrap' : 'nowrap'}}>
                            <div style={{...styles.dividedContent, marginRight: this.props.appState.mobile ? '0' : '20px'}}>
                                <div style={styles.contentTitle}>
                                    Minting Rebel NFTs with REB tokens
                                </div>
                                <div>
                                    <img width="200px" height="200px" style={{borderRadius: '10px'}} src={"https://rebellion.s3.us-west-2.amazonaws.com/rebels/staging/mini/2_level4.png" + `?cache=${this.state.cache}`} />
                                </div>
                                <a href={"https://docs.rebelliondao.com/general-information/rebel-nfts"} target={"_blank"}>
                                    <SecondaryButton style={styles.secondaryButton}>
                                        Rebel Docs
                                    </SecondaryButton>
                                </a>
                            </div>
                            <div style={{...styles.dividedContent, marginLeft: this.props.appState.mobile ? '0' : '20px'}}>
                                <div style={styles.contentTitle}>
                                    Purchasable Citizenship with REB tokens
                                </div>
                                <div style={styles.addressCardContainer}>
                                    <i className="far fa-address-card" style={styles.addressCard} />
                                </div>
                                <a href={"https://docs.rebelliondao.com/general-information/citizenship"} target={"_blank"}>
                                    <SecondaryButton style={styles.secondaryButton}>
                                        Citizenship Docs
                                    </SecondaryButton>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={styles.section}>
                        <div style={styles.title}>
                            What does a Rebel NFT do?
                        </div>
                        <div style={styles.description}>
                            Limited quantites of Rebel NFTs are minted each week. Upcoming mint information can be found on the Mint page.
                        </div>
                        <div style={styles.whatContainer}>
                            <div style={{...styles.nftContainer, flexDirection: this.props.appState.mobile ? 'column' : 'row', alignItems: this.props.appState.mobile ? 'center' : ''}}>
                                <img width="300px" height="300px" style={{borderRadius: '10px'}} src={"https://rebellion.s3.us-west-2.amazonaws.com/rebels/staging/mini/1_level3.png" + `?cache=${this.state.cache}`} />
                                <div style={styles.orderedList}>
                                    <div style={{...styles.listItem, margin: this.props.appState.mobile ? '40px 0 0' : '0 0 0 20px'}}>
                                        <div style={styles.listItemNumber}>
                                            1
                                        </div>
                                        <div style={styles.listItemContent}>
                                            Rebel NFTs can be “Activated” via sponsoring a Rebellion Citizen
                                        </div>
                                    </div>
                                    <div style={{...styles.listItem, margin: this.props.appState.mobile ? '40px 0 0' : '0 0 0 20px'}}>
                                        <div style={styles.listItemNumber}>
                                            2
                                        </div>
                                        <div style={styles.listItemContent}>
                                            Each “Active” Rebel NFT generates a minimum of 25 REB per day
                                        </div>
                                    </div>
                                    <div style={{...styles.listItem, margin: this.props.appState.mobile ? '40px 0 0' : '0 0 0 20px'}}>
                                        <div style={styles.listItemNumber}>
                                            3
                                        </div>
                                        <div style={styles.listItemContent}>
                                            Rebels can be upgraded with XP and REB to increase rewards
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.section}>
                        <div style={styles.title}>
                            Rebellion Citizenship
                        </div>
                        <div style={styles.description}>
                            Citizenship requires identity verification to prevent users from creating multiple accounts to game the system.
                        </div>
                        <div style={{...styles.citizenshipSection, flexDirection: this.props.appState.mobile ? 'column' : 'row'}}>
                            <div style={{...styles.citizenshipSubsection, marginRight: this.props.appState.mobile ? '0' : '10px'}}>
                                <div style={styles.miniTitle}>
                                    Benefits of Citizenship
                                </div>
                                <div style={styles.orderedList}>
                                    <div style={styles.listItemMini}>
                                        <div style={styles.listItemNumberMini}>
                                            <i className="fa fa-money-bill" style={styles.icon} />
                                        </div>
                                        <div style={styles.listItemContentMini}>
                                            Free daily REB tokens forever
                                        </div>
                                    </div>
                                    <div style={styles.listItemMini}>
                                        <div style={styles.listItemNumberMini}>
                                            <i className="fa fa-person-booth" style={styles.icon} />
                                        </div>
                                        <div style={styles.listItemContentMini}>
                                            Voting rights to choose the policies of the DAO
                                        </div>
                                    </div>
                                    <div style={styles.listItemMini}>
                                        <div style={styles.listItemNumberMini}>
                                            <i className="fa fa-donate" style={styles.icon} />
                                        </div>
                                        <div style={styles.listItemContentMini}>
                                            Bounties for referrals, holding REB, and promoting the DAO
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{...styles.citizenshipSubsection, margin: this.props.appState.mobile ? '20px 0 0' : '0 0 0 10px'}}>
                                <div style={styles.miniTitle}>
                                    How to Acquire Citizenship?
                                </div>
                                <div style={styles.orderedList}>
                                    <div style={styles.listItemMini}>
                                        <div style={styles.listItemNumberMini}>
                                            <i className="fas fa-id-card" style={styles.icon} />
                                        </div>
                                        <div style={styles.listItemContentMini}>
                                            Being sponsored by a Rebel NFT grants you citizenship
                                        </div>
                                    </div>
                                    <div style={styles.listItemMini}>
                                        <div style={styles.listItemNumberMini}>
                                            <i className="fas fa-id-badge" style={styles.icon} />
                                        </div>
                                        <div style={styles.listItemContentMini}>
                                            Citizenship is purchasable with REB tokens
                                        </div>
                                    </div>
                                    <div style={styles.listItemMini}>
                                        <div style={styles.listItemNumberMini}>
                                            <i className="fa fa-passport" style={styles.icon} />
                                        </div>
                                        <div style={styles.listItemContentMini}>
                                            Finalizing your citizenship requires real identity verification
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.section}>
                        <div style={styles.title}>
                            Roadmap
                        </div>
                        <div style={styles.description}>
                            All goals on the roadmap focus on the long term value & utility of the REB currency.
                        </div>
                        <div style={{...styles.roadmapSection, flexDirection: this.props.appState.mobile ? 'column' : 'row'}}>
                            <div style={styles.roadmapItem}>
                                <div style={styles.roadmapTitle}>
                                    Blockchain
                                </div>
                                <div style={styles.roadmapImage}>
                                    <i className="fa fa-fist-raised" style={styles.roadmapIcon} />
                                </div>
                                <div style={styles.roadmapInfo}>
                                    Build a new EVM compatible blockchain with the REB token, governance, and citizenship built in natively at the protocol layer.
                                </div>
                                <a href={"https://docs.rebelliondao.com/dao-information/dao-overview"} target={"_blank"}>
                                    <SecondaryButton style={styles.secondaryButton}>
                                        Protocol Docs
                                    </SecondaryButton>
                                </a>
                            </div>
                            <div style={{...styles.roadmapItem, margin: this.props.appState.mobile ? '20px 0' : '0 20px'}}>
                                <div style={styles.roadmapTitle}>
                                    Incubator
                                </div>
                                <div style={styles.roadmapImage}>
                                    <i className="fa fa-egg" style={styles.roadmapIcon} />
                                </div>
                                <div style={styles.roadmapInfo}>
                                    Use 20% of all tokens to fund an incubator focused on creating utility and economic activity for the REB token.
                                </div>
                                <a href={"https://docs.rebelliondao.com/dao-information/rebellion-incubator"} target={"_blank"}>
                                    <SecondaryButton style={styles.secondaryButton}>
                                        Incubator Docs
                                    </SecondaryButton>
                                </a>
                            </div>
                            <div style={styles.roadmapItem}>
                                <div style={styles.roadmapTitle}>
                                    Ecosystem
                                </div>
                                <div style={styles.roadmapImage}>
                                    <i className="fa fa-exchange-alt" style={styles.roadmapIcon} />
                                </div>
                                <div style={styles.roadmapInfo}>
                                    Onboard as much of the world as possible to distribute income daily in an effort to make the Rebellion ecosystem as large as possible.
                                </div>
                                <a href={"https://docs.rebelliondao.com/general-information/tokenomics"} target={"_blank"}>
                                    <SecondaryButton style={styles.secondaryButton}>
                                        Tokenomics Docs
                                    </SecondaryButton>
                                </a>
                            </div>
                        </div>
                    </div>
                    <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                        <PrimaryButton style={{...styles.primaryButton, fontSize: '20px'}}>
                            <i className="fab fa-discord" style={styles.navIcon} />
                            {`Join The Discord`}
                        </PrimaryButton>
                    </a>
                </div>
            </div>
        );
    }
}

export default Airdrop
