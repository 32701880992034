import React from "react"
const styles = {
    trade: {
        backgroundColor: '#0b0c0d',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexWrap: 'wrap',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        // backgroundColor: '#141617',
        flexDirection: 'column',
        maxWidth: '500px',
        margin: '25px',
    },
}

class Trade extends React.Component {
    render () {
        return (
            <div style={styles.trade}>
                <div style={styles.panel}>
                    Trade
                </div>
            </div>
        );
    }
}

export default Trade
