import React from "react"
import Toast from "./Toast"
import Web3Utils from "web3-utils";
import styled from "styled-components"
import * as Web3 from "web3-eth";
import {abi} from "../utils/abi";
import {paymentsAbi} from "../utils/paymentsAbi";

// import * as Web3 from "web3-eth";
const styles = {
    dashboard: {
        backgroundColor: '#16181A',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        boxSizing: 'border-box',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px 0',
    },
    panelContainer: {
        maxWidth: '800px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    miniPanel: {
        display: 'flex',
        padding: '20px',
        boxSizing: 'border-box',
        borderRadius: '20px',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        margin: '5px 0',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
    },
    headerText: {
        marginLeft: '10px',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px 20px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    rebIconMed: {
        marginLeft: '8px',
        height: '25px',
    },
    dashboardInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '15px',
    },
    middleInfo: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
    },
    miniTitle: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    miniDescription: {
        color: '#999',
        fontWeight: '12px',
        display: 'flex',
        marginBottom: '10px',
        alignItems: 'center',
        flexGrow: 1,
    },
    imageBox: {
        width: '50px',
        height: '50px',
        backgroundColor: '#0b0c0d',
        borderRadius: '10px',
    },
    rebelImageContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rebel: {
        padding: '25px 20px',
        borderRadius: '20px',
        cursor: 'pointer',
    },
    rebelImage: {
        width: '200px',
        height: '200px',
        backgroundColor: '#0b0c0d',
        borderRadius: '10px',
    },
    rebelImageLarge: {
        width: '300px',
        height: '300px',
        backgroundColor: '#0b0c0d',
        borderRadius: '10px',
    },
    allInfo: {
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    rebelStats: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    rebelInfos: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rebelInfoSide: {
    },
    rebelInfo:{
        display: 'flex',
        flexDirection: 'column',
    },
    infoHeader:{
        fontSize: '14px',
        color: '#999',
        marginBottom: '5px',
    },
    infoText: {
        fontSize: '18px',
        fontWeight: '600',
    },
    extraInfoHeader:{
        fontSize: '14px',
        color: '#999',
        marginBottom: '5px',
    },
    extraInfoText: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'right',
    },
    secondaryButton: {
        marginTop: '5px',
        padding: '10px',
        display: 'inline-flex',
        border: '1px solid #999',
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        color: '#999',
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rebIconMini: {
        marginLeft: '5px',
        height: '30px',
    },
    referralLink: {
        width: '200px',
        backgroundColor: 'black',
        padding: '10px',
        border: '1px solid #999',
        borderRadius: '10px',
        fontSize: '12px',
        textAlign: 'center',
        outline: 'none',
        color: '#999',
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2500,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    container: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '20px',
        zIndex: 3000,
        width: '800px',
        fontFamily: 'Lato, Arial',
        background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        color: 'white',
    },
    modalInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    right: {
        flexGrow: 1,
        marginLeft: '20px',
    },
    inputSection: {
        width: '100%',
        marginTop: '20px',
    },
    inputLabel: {
        fontSize: '16px',
        color: '#999',
        fontWeight: '300',
        margin: '5px 0'
    },
    upgradeTitle: {
        fontWeight: '600',
        fontSize: '20px',
        marginTop: '20px',
        marginBottom: '10px',
        color: '#CCC',
    },
    inputBox: {
        border: '1px solid #333',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '18px',
        borderRadius: '10px',
        padding: '13px 10px 10px 10px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
        outline: 'none',
    },
    buttons: {
        marginTop: '5px',
        width: '100%',
        justifyContent: 'space-between',
    },
    disabledButton: {
        backgroundColor: '#5b5b5b',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'inline-flex',
        cursor: 'not-allowed',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upgradeInfo: {
        display: 'flex',
        marginTop: '20px',
        alignItems: 'center',
    },
    upgradeReb: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    plus: {
        color: 'white',
        fontWeight: '600',
        margin: '0 30px',
        fontSize: '24px',
    },
    upgradeXp: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    upgradePrimary: {
        padding: '10px',
        boxSizing: 'border-box',
        border: '1px solid #999',
        borderRadius: '10px',
        fontWeight: '600',
        fontSize: '16px',
        width: '100%',
        textAlign: 'center',
    },
    upgradeSecondary: {
        color: '#999',
        fontSize: '14px',
        marginTop: '5px'
    },
    upgradeSection: {
        display: 'flex',
        width: '100%',
    },
    arrow: {
        margin: '0 10px',
        fontWeight: '600',
    },
    graphics: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
    }
}

const RebelBackground = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #17191a !important;
}
`

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

const SecondaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #333 !important;
}
`

let toastId = 1

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sponsorAddress: '',
            showModal: false,
            toasts: [],
            rebels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            activeRebel: null,
            paid: this.props.appState.appData.upgrade_paids > 0,
            upgraded: false,
            cache: Math.floor(Math.random() * 1000000)
        }
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    copy = () => {
        let copyText = document.getElementById("referralLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        this.state.toasts.push({
            title: 'Referral Link Copied',
            information: `You have successfully copied your referral link`,
            id: toastId++
        })
        this.setState({toasts: this.state.toasts})
    }

    renderRebels = () => {
        return this.props.nfts.map(this.renderRebel)
    }

    renderRebel = (rebel) => {
        let address = "none"
        if (rebel.address_sponsored?.length > 1) {
            address = `${rebel.address_sponsored?.substr(0, 5)}...`
        }

        return (
            <RebelBackground onClick={() => this.setState({showModal: true, activeRebel: rebel, sponsorAddress: rebel.address_sponsored})} style={styles.rebel}>
                <div style={styles.rebelImage}>
                    <img width="200px" height="200px" style={{borderRadius: '10px'}} src={rebel.thumbnail + `?cache=${this.state.cache}`} />
                </div>
                <div style={styles.allInfo}>
                    <div style={styles.rebelInfos}>
                        <div style={styles.rebelInfoSide}>
                            <div style={styles.rebelInfo}>
                                <div style={styles.infoHeader}>
                                    Status
                                </div>
                                <div style={{...styles.infoText, color: (rebel.status === 'active' ? "#00b3ff" : "#fc3903")}}>
                                    {`${rebel.status === 'active' ? 'Active' : 'Inactive'}`}
                                </div>
                            </div>
                            <div style={styles.rebelInfo}>
                                <div style={styles.infoHeader}>
                                    Sponsoring
                                </div>
                                <div style={styles.infoText}>
                                    {address}
                                </div>
                            </div>
                        </div>
                        <div style={styles.rebelInfoSide}>
                            <div style={styles.rebelInfo}>
                                <div style={styles.infoHeader}>
                                    Daily REB
                                </div>
                                <div style={styles.infoText}>
                                    {`${Number(Web3Utils.fromWei(rebel.daily_income, 'ether')).toFixed(2)}`}
                                </div>
                            </div>
                            <div style={styles.rebelInfo}>
                                <div style={styles.infoHeader}>
                                    Multiplier
                                </div>
                                <div style={styles.infoText}>
                                    {`${rebel.multiplier}x`}
                                </div>
                            </div>
                        </div>
                    </div>
                    <SecondaryButton style={styles.secondaryButton}>
                        Update
                    </SecondaryButton>
                </div>
            </RebelBackground>
        )
    }

    renderRebelNFTs = () => {
        if (this.props.nfts.length === 0) {
            return (
                <PrimaryButton onClick={this.props.setPage.bind(this, 'mint')} style={{...styles.primaryButton, fontSize: '20px'}}>
                    Mint Rebel NFT
                </PrimaryButton>
            )
        } else {
            return (
                <div style={styles.rebelImageContainer}>
                    {this.renderRebels()}
                </div>
            )
        }
    }

    claim = async () => {
        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/claims`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from sponsor', res)
                if (res.success) {
                    this.state.toasts.push({
                        title: `${Number(Web3Utils.fromWei(this.props.unclaimed_token?.wei || "0", 'ether')).toFixed(2)} REB Claimed`,
                        information: `You have successfully claimed your income`,
                        id: toastId++
                    })
                    this.props.resetUnclaimed()

                    this.setState({toasts: this.state.toasts})
                } else {

                }
            })
            .catch(err => {

            })
    }

    sponsor = async (address, nft) => {
        const body = {address_to_sponsor: address.toLowerCase(), nft_id: nft.blockchain_id}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const currentlySponsoringCurrentCitizen = this.props.appState.appData.current_citizen?.nft_sponsoring?.id === nft?.id

        fetch(`${window.location.origin}/api/v0/nfts/sponsor`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from sponsor', res)
                if (res.success) {
                    nft.status = res.status
                    let nfts = this.props.nfts
                    for (let i = 0; i < nfts.length; i++) {
                        if (nfts[i].id === nft.id) {
                            nfts[i].status = res.status
                            nfts[i].address_sponsored = address
                        }
                    }
                    this.props.setNfts(nfts)
                    if (address.toLowerCase() === this.props.appState.appData.current_citizen.address) {
                        this.props.setSponsorNft(nft)
                    }
                    if (currentlySponsoringCurrentCitizen && address.toLowerCase() !== this.props.appState.appData.current_citizen.address) {
                        this.props.setSponsorNft(null)
                    }
                    this.state.toasts.push({
                        title: 'Sponsored Address Changed',
                        information: `You have successfully changed the NFT sponsor address`,
                        id: toastId++
                    })
                    this.setState({toasts: this.state.toasts})
                    this.closeModal()
                } else {
                    this.state.toasts.push({
                        title: 'Error',
                        information: res.errors[0],
                        id: toastId++
                    })
                    this.setState({toasts: this.state.toasts})
                    this.closeModal()
                }
            })
            .catch(err => {

            })
    }

    finalizeUpgrade = async() => {
        const body = {nft_id: this.state.activeRebel.blockchain_id}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/nfts/upgrade`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from sponsor', res)
                if (res.success) {
                    this.props.setUpgradePaids(this.props.appState.appData.upgrade_paids - 1)
                    this.props.loadNftData(this.props.appState.nft_ids)

                    this.state.toasts.push({
                        title: `Rebel Upgraded`,
                        information: `You have successfully upgraded your rebel`,
                        id: toastId++
                    })
                    this.setState({toasts: this.state.toasts, showModal: false, upgraded: true})
                } else {

                }
            })
            .catch(err => {

            })
    }

    upgrade = async() => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let myContract = new web3Instance.Contract(paymentsAbi, this.props.appState.contractAddresses.payments_contract_address, { gasLimit: "200000" })
        let data = myContract.methods.payForUpgrade().encodeABI()

        const transactionParameters = {
            to: this.props.appState.contractAddresses.payments_contract_address,
            from: window?.ethereum?.selectedAddress,
            data: data
        }

        let txHash = null

        try {
            txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            })
            this.setState({polling: true})
            this.pollForUpgradePaid()
        } catch (err) {
            console.log('nope')
            console.log(err)
        }

        return txHash
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    nextUrl = (url, newLevel) => {
        let i = url.lastIndexOf(".");
        return url.substr(0, i - 1) + newLevel + '.png'
    }

    renderUpgradeButton = () => {
        let balance = Number(this.props.appState.balance)
        let cost = Number(Web3Utils.fromWei(("2000000000000000000000"), 'ether'))

        let currentAllowance = this.props.appState.allowance
        let needsAllowance = currentAllowance < Number("2000000000000000000000")

        let canPurchase = true
        let errorMessage = ''

        if (balance < cost && !this.state.paid) {
            canPurchase = false
            errorMessage = 'Insufficient REB'
        }

        if (this.props.appState?.appData?.current_citizen?.xp < 500 && !this.state.paid) {
            canPurchase = false
            errorMessage = 'Insufficient XP'
        }

        if (this.state.upgraded) {
            canPurchase = false
            errorMessage = 'Purchased'
        }

        if (this.state.activeRebel.level === 4) {
            canPurchase = false
            errorMessage = 'Max Level'
        }

        if (this.state.polling) {
            return (
                <div style={{...styles.disabledButton, fontSize: '20px', marginRight: '20px'}}>
                    Processing...
                </div>
            )
        }

        if (!canPurchase) {
            return (
                <div style={{...styles.disabledButton, marginRight: '20px'}}>
                    {errorMessage}
                </div>
            )
        }


        if (needsAllowance) {
            return (
                <PrimaryButton onClick={this.requestAllowance.bind(this)} style={{...styles.primaryButton, marginRight: '20px'}}>
                    Approve REB
                </PrimaryButton>
            )
        }

        if (this.state.paid) {
            return (
                <PrimaryButton onClick={this.finalizeUpgrade.bind(this)} style={{...styles.primaryButton, marginRight: '20px'}}>
                    Finalize Upgrade
                </PrimaryButton>
            )
        }

        return (
            <PrimaryButton onClick={this.upgrade.bind(this, this.state.activeRebel)} style={{...styles.primaryButton, marginRight: '20px'}}>
                Upgrade Rebel
            </PrimaryButton>
        )
    }

    nftModal = () => {

        if (!this.state.showModal) {
            return null
        }

        let rebel = this.state.activeRebel

        let address = "none"
        if (rebel.address_sponsored?.length > 1) {
            address = `${rebel.address_sponsored?.substr(0, 5)}...`
        }


        let balance = Number(this.props.appState.balance)
        let cost = Number(Web3Utils.fromWei(("2000000000000000000000"), 'ether'))

        let sufficientBalance = balance >= cost
        let sufficientXp = this.props.appState?.appData?.current_citizen?.xp >= 500

        return (
            <div style={styles.modalContainer}>
                <div style={styles.container}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-fire" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Rebel NFT
                                </span>
                            </div>
                            <div style={styles.description}>
                                Manage your Rebel NFT
                            </div>
                        </div>
                        <div>
                            <div style={styles.extraInfo}>
                                <div style={styles.extraInfoHeader}>
                                    Daily REB Generated
                                </div>
                                <div style={styles.extraInfoText}>
                                    {`+${Number(Web3Utils.fromWei(rebel.daily_income, 'ether')).toFixed(2)} REB`}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={styles.modalInfo}>
                        <div style={styles.left}>
                            <div style={styles.rebelImageLarge}>
                                <img width="300px" height="300px" style={{borderRadius: '10px'}} src={rebel.thumbnail + `?cache=${this.state.cache}`} />
                            </div>
                        </div>
                        <div style={styles.right}>
                            <div style={styles.rebelStats}>
                                <div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Status
                                        </div>
                                        <div style={{...styles.infoText, color: (rebel.status === 'active' ? "#00b3ff" : "#fc3903")}}>
                                            {`${rebel.status === 'active' ? 'Active' : 'Inactive'}`}
                                        </div>
                                    </div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Sponsoring
                                        </div>
                                        <div style={styles.infoText}>
                                            {address}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Daily REB
                                        </div>
                                        <div style={styles.infoText}>
                                            {`+${Number(Web3Utils.fromWei(rebel.daily_income, 'ether')).toFixed(2)} REB`}
                                        </div>
                                    </div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Total Multiplier
                                        </div>
                                        <div style={styles.infoText}>
                                            {`${rebel.multiplier}x`}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Skin
                                        </div>
                                        <div style={{...styles.infoText}}>
                                            {`${rebel.skin}`}
                                        </div>
                                    </div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Level
                                        </div>
                                        <div style={styles.infoText}>
                                            {`${rebel.level}`}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Verifications
                                        </div>
                                        <div style={styles.infoText}>
                                            {`${rebel.verifications}`}
                                        </div>
                                    </div>
                                    <div style={styles.rebelInfo}>
                                        <div style={styles.infoHeader}>
                                            Last Claim
                                        </div>
                                        <div style={styles.infoText}>
                                            {rebel.last_claim}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={styles.inputLabel}>
                                    Account To Sponsor
                                </div>
                                <input style={styles.inputBox} placeholder={"Enter ETH address"} name="sponsorAddress" value={this.state.sponsorAddress} onChange={this.onChange.bind(this)}>

                                </input>
                                <div style={styles.buttons}>
                                    <PrimaryButton onClick={this.sponsor.bind(this, this.state.sponsorAddress, this.state.activeRebel)} style={{...styles.primaryButton, marginRight: '20px'}}>
                                        Sponsor
                                    </PrimaryButton>
                                    <SecondaryButton onClick={this.sponsor.bind(this, '', this.state.activeRebel)} style={{...styles.secondaryButton}}>
                                        Stop Sponsoring
                                    </SecondaryButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <div style={styles.upgradeTitle}>
                            Upgrade your Rebel
                        </div>
                        <div style={styles.upgradeSection}>
                            <div>
                                <div style={styles.graphics}>
                                    <div style={styles.leftGraphic}>
                                        <img width="140px" height="140px" style={{borderRadius: '10px'}} src={rebel.thumbnail + `?cache=${this.state.cache}`} />
                                    </div>
                                    <div style={styles.arrow}>
                                        →
                                    </div>
                                    <div style={styles.rightGraphic}>
                                        <img width="140px" height="140px" style={{borderRadius: '10px'}} src={this.nextUrl(rebel.thumbnail, rebel.level + 1) + `?cache=${this.state.cache}`} />
                                    </div>
                                </div>
                            </div>
                            <div style={{marginLeft: '50px'}}>
                                <div style={styles.upgradeInfo}>
                                    <div style={styles.upgradeReb}>
                                        <div style={styles.upgradePrimary}>
                                            2K REB
                                        </div>
                                        <div style={{...styles.upgradeSecondary, color: sufficientBalance ? '#00b3ff' : '#999'}}>
                                            {`${Number(this.props.appState.balance)} REB Available`}
                                        </div>
                                    </div>
                                    <div style={styles.plus}>
                                        +
                                    </div>
                                    <div style={styles.upgradeXp}>
                                        <div style={styles.upgradePrimary}>
                                            500 XP
                                        </div>
                                        <div style={{...styles.upgradeSecondary, color: sufficientXp ? '#00b3ff' : '#999'}}>
                                            {`${this.props.appState?.appData?.current_citizen?.xp} XP Available`}
                                        </div>
                                    </div>
                                </div>
                                <div style={{...styles.buttons, marginTop: '15px'}}>
                                    {this.renderUpgradeButton()}
                                    <a href={`https://docs.rebelliondao.com/general-information/rebel-nfts`} target={"_blank"}>
                                        <SecondaryButton style={{...styles.secondaryButton}}>
                                            Learn More
                                        </SecondaryButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => this.closeModal()} style={styles.background} />
            </div>
        )
    }

    closeModal() {
        this.setState({showModal: false})
    }

    requestAllowance = async() => {
        let currentAllowance = this.props.appState.allowance
        let needsAllowance = currentAllowance < Number("2000000000000000000000")

        if (!needsAllowance) {
            return new Promise(resolve => resolve(true))
        }

        return new Promise(async(resolve, reject) => {
            const web3Instance = new Web3('https://polygon-rpc.com/')
            let myContract = new web3Instance.Contract(abi, this.props.appState.contractAddresses.token_contract_address, { gasLimit: "200000" })
            let data = myContract.methods.increaseAllowance(this.props.appState.contractAddresses.payments_contract_address, "1000000000000000000000000").encodeABI()

            const transactionParameters = {
                to: this.props.appState.contractAddresses.token_contract_address,
                from: window?.ethereum?.selectedAddress,
                data: data
            }

            let txHash = null

            try {
                txHash = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                })
                this.setState({polling: true})
                let poll = setInterval(async() => {
                    let allowance = await myContract.methods.allowance(window?.ethereum?.selectedAddress, this.props.appState.contractAddresses.payments_contract_address).call()
                    console.log('allowance', allowance)
                    if (allowance > 0) {
                        this.setState({polling: false})
                        this.props.setAllowance(Number(allowance))
                        clearInterval(poll)
                    }
                }, 2000)
            } catch (err) {
                console.log('nope')
                console.log(err)
            }

            this.props.setAllowance(this.props.appState.allowance + Number(this.props.next_drawing?.wei || "0"))

            resolve(txHash)
        })
    }

    renderSponsorSection = () => {
        const isCitizen = this.props.appState?.appData?.current_citizen?.status === "citizen"
        if (!isCitizen) {
            return (
                <div style={styles.middleInfo}>
                    <div style={styles.miniTitle}>
                        Become a Citizen
                    </div>
                    <div style={{...styles.miniDescription, maxWidth: '250px'}}>
                        {`All citizens receive up to ${Number(Web3Utils.fromWei(this.props.stats.base_daily_reb, 'ether')).toFixed(2)} REB daily (~$${(this.props.stats.reb_price * Number(Web3Utils.fromWei(this.props.stats.base_daily_reb, 'ether'))).toFixed(2)})`}
                    </div>
                    <PrimaryButton onClick={this.props.setPage.bind(this, 'verify')} style={{...styles.primaryButton, fontSize: '20px'}}>
                        {`Register`}
                    </PrimaryButton>

                </div>
            )
        }
        if (!this.props.appState.appData?.current_citizen?.nft_sponsoring) {return (
            <div style={styles.middleInfo}>
                <div style={styles.miniTitle}>
                    NFT Sponsor
                </div>
                <div style={styles.miniDescription}>
                    {`No Active NFT Sponsoring`}
                </div>
                <PrimaryButton onClick={this.props.setPage.bind(this, 'mint')} style={{...styles.primaryButton, fontSize: '20px'}}>
                    {`Mint a Rebel`}
                </PrimaryButton>

            </div>
        )
        }

        return (
            <div style={styles.middleInfo}>
                <div style={styles.miniTitle}>
                    NFT Sponsor
                </div>
                <div style={styles.miniDescription}>
                    {`Level ${this.props.appState.appData?.current_citizen?.nft_sponsoring?.level} - 1.5x Multiplier`}
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={styles.imageBox}>
                        <img width="50px" height="50px" style={{borderRadius: '10px'}} src={`${this.props.appState.appData?.current_citizen?.nft_sponsoring?.thumbnail}`} />
                    </div>
                    <div>
                        <SecondaryButton onClick={this.sponsor.bind(this,"", this.props.appState.appData?.current_citizen?.nft_sponsoring)} style={{...styles.secondaryButton, marginTop: 0, marginLeft: '10px'}}>
                            Remove Sponsor
                        </SecondaryButton>
                    </div>
                </div>
            </div>
        )
    }

    pollForUpgradePaid = () => {
        let timesToPoll = 100
        this.setState({polling: true})
        let poll = setInterval(() => {
            const body = {}
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/nfts/upgrade_paid`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(async(res) => {
                    timesToPoll -= 1
                    console.log('Got response from poll', res)
                    if (res.success) {
                        if (res.result) {
                            this.setState({paid: true, polling: false})
                            clearInterval(poll)
                        }
                    }

                    if (timesToPoll === 0) {
                        clearInterval(poll)
                    }
                })
                .catch(err => {

                })
        }, 5000)
    }

    render () {

        let canClaim = this.props.unclaimed_token?.wei.length > 1

        let rank = "Observer"

        if (this.props.appState.appData.current_citizen.status === "citizen") {
            rank = this.props.appState.appData.current_citizen.rank.name
        }

        let currentBase = Number(Web3Utils.fromWei(this.props.appState.appData.current_income_adjustment?.base_daily_reb || "0", 'ether'))
        // let currentReferral = Number(Web3Utils.fromWei(this.props.appState.appData.current_income_adjustment?.referral_bonus_wei || "0", 'ether'))
        let currentCitizenshipCost = Number(Web3Utils.fromWei(this.props.appState.appData.current_income_adjustment?.citizen_cost || "0", 'ether'))

        let nextBase = Number(Web3Utils.fromWei(this.props.appState.appData.next_income_adjustment?.base_daily_reb || "0", 'ether'))
        // let nextReferral = Number(Web3Utils.fromWei(this.props.appState.appData.next_income_adjustment?.referral_bonus_wei || "0", 'ether'))
        let nextCitizenshipCost = Number(Web3Utils.fromWei(this.props.appState.appData.next_income_adjustment?.citizen_cost || "0", 'ether'))

        return (
            <div style={styles.dashboard}>
                <div style={styles.panel}>
                    <div style={{...styles.headerContainer}}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-home" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Dashboard
                                </span>
                            </div>
                            <div style={{...styles.description, marginBottom: 0}}>
                                Your Rebellion Account Information
                            </div>
                        </div>
                        <div>
                            <div style={styles.rebelInfo}>
                                <div style={styles.extraInfoHeader}>
                                    Rank
                                </div>
                                <div style={styles.extraInfoText}>
                                    {rank}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{...styles.panelContainer, flexWrap: this.props.appState.mobile ? 'wrap' : 'nowrap'}}>
                    <div style={styles.miniPanel}>
                        <div style={styles.middleInfo}>
                            <div style={styles.miniTitle}>
                                Collect
                            </div>
                            <div style={styles.miniDescription}>
                                {`+${Number(Web3Utils.fromWei(this.props.appState.appData.current_citizen.total_income, 'ether')).toFixed(2)} REB daily`}
                            </div>
                            {
                                canClaim && (
                                    <PrimaryButton onClick={this.claim.bind(this)} style={{...styles.primaryButton, fontSize: '20px'}}>
                                        {`Claim ${Number(Web3Utils.fromWei(this.props.unclaimed_token?.wei || "0", 'ether')).toFixed(2)}`}
                                        <span>
                                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                    </span>
                                    </PrimaryButton>
                                )
                            }
                            {
                                !canClaim && (
                                    <div style={{...styles.disabledButton, fontSize: '20px'}}>
                                        {`Claim ${Number(Web3Utils.fromWei(this.props.unclaimed_token?.wei || "0", 'ether')).toFixed(2)}`}
                                        <span>
                                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                    </span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div style={{...styles.miniPanel, margin: this.props.appState.mobile ? '20px 0' : '5px 20px'}}>
                        {this.renderSponsorSection()}
                    </div>
                    <div style={styles.miniPanel}>
                        <div style={styles.middleInfo}>
                            <div style={styles.miniTitle}>
                                Referral
                            </div>
                            <div style={styles.miniDescription}>
                                {`3-400 REB tokens per referral`}
                            </div>
                            <input id={"referralLink"} style={styles.referralLink} value={`https://rebellionbase.com/?referred_by=${this.props.appState.appData.current_citizen.address}`} />
                            <div style={{marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <PrimaryButton onClick={this.copy.bind(this)} style={{...styles.primaryButton, padding: '10px 20px'}}>
                                    Copy
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.panel}>
                    <div style={{...styles.headerContainer, flexWrap: 'wrap'}}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-coins" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                Next Income Adjustment
                            </span>
                            </div>
                            <div style={{...styles.description, marginBottom: 0}}>
                                Citizenship cost & payouts are adjusted on a weekly basis to keep them at a sustainable level. Below shows the next adjustment that will occur.
                            </div>
                        </div>
                        <div style={{display: 'flex', marginTop: '40px', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between'}}>
                            <div style={{...styles.rebelInfo, marginRight: '40px'}}>
                                <div style={styles.extraInfoHeader}>
                                    Income
                                </div>
                                <div style={styles.extraInfoText}>
                                    {`${currentBase} => ${nextBase} REB`}
                                </div>
                            </div>
                            <div style={{...styles.rebelInfo, marginRight: '40px'}}>
                                <div style={styles.extraInfoHeader}>
                                    Time To Change
                                </div>
                                <div style={styles.extraInfoText}>
                                    {`${this.props.appState.appData.days_to_income_adjustment} Day${this.props.appState.appData.days_to_income_adjustment === 1 ? "" : "s"}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.panel}>

                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-fire" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Rebel NFTs
                                </span>
                            </div>
                            <div style={styles.description}>
                                Manage your Rebel NFTs here!
                            </div>
                        </div>
                        <div>
                            <div style={styles.extraInfo}>
                                <div style={{...styles.extraInfoHeader, textAlign: 'right'}}>
                                    XP Available for Upgrades
                                </div>
                                <div style={styles.extraInfoText}>
                                    {`${this.props.appState?.appData?.current_citizen?.xp} XP`}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRebelNFTs()}
                </div>
                {this.nftModal()}
                {this.renderToasts()}
            </div>
        );
    }
}

export default Dashboard
