import React from "react"
import styled from "styled-components"

const styles = {
    checkbox: {
        borderRadius: '5px',
        width: '30px',
        height: '30px',
        border: '1px solid #333',
        backgroundColor: '#111',
        color: 'white',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        transition: 'all .2s ease',
    },
    active: {
        transition: 'none',
        border: '1px solid #333',
        backgroundColor: '#00b3ff',
    }
}

const CheckboxContainer = styled.div`
`

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.active || false
        }
    }

    updateActive(){
        this.state.active = !this.state.active;
        this.setState({active: this.state.active});
        if(this.props.updateFn){
            this.props.updateFn(this.state.active);
        }
    }

    render() {
        return (
            <CheckboxContainer className="Checkbox">
                <input type="hidden" name={(this.props.model && this.props.field) ? (this.props.model + '[' + this.props.field + ']') : null} value={this.state.active} />
                <div style={{...styles.checkbox, ...(this.state.active ? styles.active : {})}} onClick={() => this.updateActive()}>
                    {this.state.active ? (<div className={"fa fa-check"} />) : null }
                </div>
            </CheckboxContainer>
        )
    }
}

export default Checkbox