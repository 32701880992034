import React from "react"
import styled from "styled-components"

const styles = {
    container: {
        position: 'fixed',
        bottom: '10px',
        zIndex: '150',
        borderLeft: '3px solid #00b3ff',
        borderRight: '1px solid #111',
        borderTop: '1px solid #111',
        borderBottom: '1px solid #111',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        width: '300px',
        fontFamily: 'Lato, Arial',
        backgroundColor: '#16181A'
    },
    title: {
        fontWeight: '600',
        fontSize: '24px',
        padding: 20,
    },
    divider: {
        height: '1px',
        backgroundColor: '#000',
        width: '100%',
    },
    information: {
        padding: 20,
    }
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.5) 0px 2px 8px;
background-color: white;
`

const targetLeft = 10

class Toast extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            left: -310,
            middleDuration: 200,
            hitCenter: false,
        }
    }

    componentDidMount() {
        setInterval(() => {
            if (!this.state.hitCenter && targetLeft - this.state.left < 0.3) {
                this.setState({left: targetLeft, hitCenter: true})
            } else if(!this.state.hitCenter) {
                this.setState({left:  this.state.left + ((targetLeft - this.state.left)/10)})
            } else if (this.state.middleDuration > 0) {
                this.setState({middleDuration: this.state.middleDuration - 1})
            }else {
                this.setState({left:  this.state.left + ((-310 - this.state.left)/10)})
            }
        }, 20)
    }

    render() {
        return (
            <Container style={{...styles.container, left: `${this.state.left}px`}}>
                <div style={styles.title}>
                    {this.props.title}
                </div>
                <div style={styles.divider} />
                <div style={styles.information}>
                    {this.props.information}
                </div>
            </Container>
        )
    }
}

export default Toast