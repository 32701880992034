import React from "react"
import Web3Utils from "web3-utils";
import {abi} from "../utils/abi";
import * as Web3 from "web3-eth";
import Checkbox from "./Checkbox";
import styled from "styled-components";
import SumsubWebSdk from '@sumsub/websdk-react'
import {citizenPaymentsAbi} from "../utils/citizenPaymentsAbi";

const styles = {
    verify: {
        backgroundColor: '#16181A',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        // background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
    },
    headerText: {
        marginLeft: '10px',
    },
    dashboardInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '15px',
    },
    middleInfo: {
        margin: '0 50px',
        minWidth: '200px',
    },
    miniTitle: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    countdown: {
        fontWeight: '600',
        fontSize: '30px',
        marginBottom: '4px',
    },
    miniDescription: {
        color: '#999',
        fontWeight: '12px',
        marginBottom: '15px',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        fontSize: '24px',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    disabledButton: {
        backgroundColor: '#5b5b5b',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        cursor: 'not-allowed',
        justifyContent: 'center',
    },
    rebIconMed: {
        marginLeft: '8px',
        height: '25px',
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 2500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    container: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '20px',
        width: '800px',
        zIndex: 3000,
        fontFamily: 'Lato, Arial',
        background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        color: 'white',
    },
    modalInfo: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    selectStyle: {
        webkitAppearance: 'none',
        mozAppearance: 'none',
        appearance: 'none',
        height: '48px',
        border: '1px solid #666',
        backgroundColor: '#16181A',
        width: '266px',
        textIndent: '20px',
        fontSize: '18px',
        color: 'white',
    },
    optionStyle: {
        fontSize: '18px'
    },
    notificationSection: {
        marginTop: '20px',
        alignItems: 'center',
        display: 'flex',
    },
    notificationTitle: {
        color: '#CCC',
        fontWeight: '400',
        fontSize: '16px',
        marginLeft: '10px',
    },
    modalSection: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    }
}

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

class Verify extends React.Component {
    constructor(props) {
        super(props)

        let applicationPaid = this.props.appState.appData.current_citizen.citizenship_paid
        let verified = this.props.appState.appData.current_citizen.status === 'verified'
        let countryPaid = this.props.appState.appData.current_citizen.country_paid
        let isCitizen = this.props.appState.appData.current_citizen.status === 'citizen'

        let step = 'application'

        if (applicationPaid) {
            step = 'sumsub'
        }

        if (verified) {
            step = 'countryPayment'
        }

        if ((countryPaid && verified) || isCitizen) {
            step = 'complete'
        }

        console.log(step)

        this.state = {
            showModal: false,
            applicant_id: null,
            accessToken: null,
            paid: false,
            countryPaid: false,
            step: step,
            countryMultiplier: this.props.appState.countryMultiplier,
            country: this.props.appState.country,
            agree1: false,
            agree2: false,
            agree3: false,
            agree4: false,
            agree5: false,
        }
    }

    toNum = (wei) => {
        return  Number(Web3Utils.fromWei(wei, 'ether')).toFixed(2)
    }

    applyCountryMultiplier = (wei) => {
        let pre = Number(Web3Utils.fromWei(wei, 'ether')).toFixed(1)
        return (pre * this.state.countryMultiplier).toFixed(2)
    }

    requestAllowance = async() => {
        let currentAllowance = this.props.appState.citizen_allowance
        let needsAllowance = currentAllowance < (Number(this.props.stats.citizen_cost) + Number(this.applyCountryMultiplier(this.props.stats.country_fee)))
        console.log('allowances', currentAllowance, (Number(this.props.stats.citizen_cost) + Number(this.applyCountryMultiplier(this.props.stats.country_fee))))

        if (!needsAllowance) {
            return new Promise(resolve => resolve(true))
        }

        return new Promise(async(resolve, reject) => {
            const web3Instance = new Web3('https://polygon-rpc.com/')
            let myContract = new web3Instance.Contract(abi, this.props.appState.contractAddresses.token_contract_address, { gasLimit: "200000" })
            let data = myContract.methods.increaseAllowance(this.props.appState.contractAddresses.citizen_payments_contract_address, "1000000000000000000000000").encodeABI()

            const transactionParameters = {
                to: this.props.appState.contractAddresses.token_contract_address,
                from: window?.ethereum?.selectedAddress,
                data: data
            }

            let txHash = null

            try {
                txHash = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                })
                this.setState({polling: true})
                let poll = setInterval(async() => {
                    let allowance = await myContract.methods.allowance(window?.ethereum?.selectedAddress, this.props.appState.contractAddresses.citizen_payments_contract_address).call()
                    console.log('allowance', allowance)
                    if (allowance > 0) {
                        this.setState({polling: false})
                        this.props.setCitizenAllowance(Number(allowance))
                        clearInterval(poll)
                    }
                }, 2000)
            } catch (err) {
                console.log('nope')
                console.log(err)
            }

            // this.props.setAllowance(this.props.appState.allowance + Number(this.props.stats.citizen_cost))

            resolve(txHash)
        })
    }

    payForCountry = async() => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let countryCode = this.props.appState.countryCodes[this.state.country]
        console.log(countryCode)
        if (!countryCode) {
            return
        }
        let myContract = new web3Instance.Contract(citizenPaymentsAbi, this.props.appState.contractAddresses.citizen_payments_contract_address, { gasLimit: "200000" })
        let data = myContract.methods.payForCountry(countryCode).encodeABI()

        const transactionParameters = {
            to: this.props.appState.contractAddresses.citizen_payments_contract_address,
            from: window?.ethereum?.selectedAddress,
            data: data
        }

        let txHash = null

        try {
            txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            })
            this.setState({polling: true})
            this.pollForCountryPayment()
        } catch (err) {
            console.log('nope')
            console.log(err)
        }

        return txHash
    }

    payForCitizenship = async() => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let myContract = new web3Instance.Contract(citizenPaymentsAbi, this.props.appState.contractAddresses.citizen_payments_contract_address, { gasLimit: "200000" })
        let data = myContract.methods.payForCitizenship().encodeABI()

        const transactionParameters = {
            to: this.props.appState.contractAddresses.citizen_payments_contract_address,
            from: window?.ethereum?.selectedAddress,
            data: data
        }

        let txHash = null

        try {
            txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            })
            this.setState({polling: true})
            this.pollForCitizenshipPayment()
        } catch (err) {
            console.log('nope')
            console.log(err)
        }

        return txHash
    }

    sendTransaction = async(res) => {
        let tx = await this.requestAllowance()
        if (tx !== null) {
            this.payForCitizenship()
        }
        return
    }

    createCryptoPayment() {
        const body = {citizen_address: this.props.appState.appData.current_citizen.address}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/crypto_payments`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                console.log('Got response from crate crypto payment', res)
                if (res.success) {
                    this.sendTransaction(res)
                } else {

                }
            })
            .catch(err => {

            })
    }

    verify = () => {
        if (this.state.step !== "sumsub") {
            return
        }
        console.log('Opening verification stuff')
        this.setState({showModal: true, accessToken: null})

        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/verification_passes/use`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                console.log('Got response from verify', res)
                if (res.success) {
                    this.setState({applicant_id: res.applicant_id, accessToken: res.access_token})
                } else {

                }
            })
            .catch(err => {

            })
    }

    renderSumsub = () => {
        return (
            <div style={styles.modalInfo}>
                {this.state.accessToken &&
                <SumsubWebSdk
                    accessToken={this.state.accessToken}
                    style={{height: '600px', overflowY: 'scroll'}}
                    onApplicantSubmitted={ this.pollForVerified }
                    expirationHandler={() => {
                    }}
                    config={{}}
                    options={{}}
                    onMessage={() => {
                    }}
                    onError={() => {
                    }}
                />
                }
            </div>
        )
    }

    closeModal() {
        this.setState({showModal: false, accessToken: null})
    }

    renderPurchase = () => {
        let balance = Number(this.props.appState.balance)
        let cost = this.state.step === 'application' ? Number(Web3Utils.fromWei(this.props.stats.citizen_cost, 'ether')) :  Number(this.applyCountryMultiplier(this.props.stats.country_fee))


        let canPurchase = true
        let canPurchaseCountry = false
        let errorMessage = ''

        let currentAllowance = this.props.appState.citizen_allowance
        console.log('allowance', currentAllowance)
        let paid
        if (this.state.step === 'application') {
            paid = this.props.appState.appData.current_citizen.citizenship_paid || this.state.paid
        } else {
            paid = this.props.appState.appData.current_citizen.country_paid || this.state.countryPaid
        }

        let needsAllowance = (currentAllowance < cost) && !paid
        console.log("na", needsAllowance)

        if (balance < cost) {
            canPurchase = false
            errorMessage = 'Insufficient REB'
        }

        if (this.state.step === 'application' && ( !this.state.agree1 || !this.state.agree2 || !this.state.agree3 || !this.state.agree4 || !this.state.agree5)) {
            canPurchase = false
            errorMessage = 'Must Agree To Terms'
        }

        if (this.props.appState.appData.current_citizen.status === 'verified' && this.state.step === 'application') {
            canPurchase = false
            errorMessage = 'Already Verified'
        }

        if (this.state.polling) {
            return (
                <div style={{...styles.disabledButton, fontSize: '20px'}}>
                    Processing...
                </div>
            )
        }

        let needSwitch = !this.props.appState.onPolygon

        if (needSwitch) {
            return (
                <PrimaryButton onClick={this.props.appState.switchToPolygon.bind(this)} style={{...styles.primaryButton}}>
                    Switch To Polygon
                </PrimaryButton>
            )
        }

        if (needsAllowance && balance >= cost) {
            return (
                <PrimaryButton onClick={this.requestAllowance.bind(this)} style={{...styles.primaryButton}}>
                    Approve REB
                </PrimaryButton>
            )
        }

        if (this.state.step === 'countryPayment' && canPurchase) {
            return (
                <PrimaryButton onClick={this.payForCountry.bind(this)} style={{...styles.primaryButton}}>
                    {`Purchase - ${Number(this.applyCountryMultiplier(this.props.stats.country_fee))}`}
                    <span>
                        <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                    </span>
                </PrimaryButton>
            )
        }

        if (canPurchase) {
            return (
                <PrimaryButton onClick={this.payForCitizenship.bind(this)} style={{...styles.primaryButton}}>
                    {`Application Fee - ${Number(Web3Utils.fromWei(this.props.stats.citizen_cost, 'ether')).toFixed(2)}`}
                    <span>
                        <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                    </span>
                </PrimaryButton>
            )
        }

        return (
            <div style={{...styles.disabledButton, fontSize: '20px'}}>
                {errorMessage}
            </div>
        )
    }

    pollForVerified = () => {
        let timesToPoll = 100
        let poll = setInterval(() => {
            const body = {}
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/citizens/verified`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(async(res) => {
                    timesToPoll -= 1
                    console.log('Got response from poll', res)
                    if (res.success) {
                        if (res.result) {
                            this.props.setVerificationStatus(res.status)
                            let step = this.props.appState.appData.current_citizen.country_paid ? 'complete' : 'countryPayment'
                            if (step === 'complete') {
                                this.closeModal()
                            }
                            let country = res.country
                            // if (event.target.name === "country") {
                            //     this.setState({countryMultiplier: this.props.appState.countryMultipliers[event.target.value]})
                            // }
                            // this.setState({ [event.target.name]: event.target.value });
                            this.setState({step: step, country: country, countryMultiplier: this.props.appState.countryMultipliers[country]})
                            clearInterval(poll)
                        }
                    }

                    if (timesToPoll === 0) {
                        clearInterval(poll)
                    }
                })
                .catch(err => {

                })
        }, 5000)
    }

    pollForCitizenshipPayment = () => {
        let timesToPoll = 100
        this.setState({polling: true})
        let poll = setInterval(() => {
            const body = {}
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/citizens/citizenship_paid`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(async(res) => {
                    timesToPoll -= 1
                    console.log('Got response from poll', res)
                    if (res.success) {
                        if (res.result) {
                            clearInterval(poll)
                            await this.setState({paid: true, polling: false, step: 'sumsub'})
                            this.verify();
                        }
                    }

                    if (timesToPoll === 0) {
                        clearInterval(poll)
                    }
                })
                .catch(err => {

                })
        }, 5000)
    }

    pollForCountryPayment = () => {
        let timesToPoll = 100
        this.setState({polling: true})
        let poll = setInterval(() => {
            const body = {}
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/citizens/country_paid`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(async(res) => {
                    timesToPoll -= 1
                    console.log('Got response from poll', res)
                    if (res.success) {
                        if (res.result) {
                            this.props.setVerificationStatus(res.status)
                            this.setState({countryPaid: true, polling: false, step: "complete"})
                            this.closeModal()
                            clearInterval(poll)
                        }
                    }

                    if (timesToPoll === 0) {
                        clearInterval(poll)
                    }
                })
                .catch(err => {

                })
        }, 5000)
    }

    handleChange = (event) => {
        if (event.target.name === "country") {
            this.setState({countryMultiplier: this.props.appState.countryMultipliers[event.target.value]})
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    renderCountryOptions = () => {
        let res = []
        for(let key in this.props.appState.countryMultipliers) {
            res.push(key)
        }
        res = res.sort()
        res = res.map((country) => <option style={styles.optionStyle} value={country}> {country} </option>)
        return res
    }

    renderChooseCountry = () => {
        return (
            <div style={{alignSelf: 'flex-start'}}>
                <div style={styles.modalSection}>
                    <div>
                        <div style={styles.miniTitle}>
                            Choose Country
                        </div>
                        <div style={{...styles.miniDescription}}>
                            Pick the country that you are a citizen of
                        </div>
                        <div>
                            <select style={styles.selectStyle} name="country" value={this.state.country} onChange={this.handleChange}>
                                {this.renderCountryOptions()}
                            </select>
                        </div>
                    </div>
                    <div>
                        <div style={styles.miniTitle}>
                            Daily Income
                        </div>
                        <div style={{...styles.miniDescription}}>
                            Based on your country
                        </div>
                        <div style={{...styles.miniTitle}}>
                            {`${this.applyCountryMultiplier(this.props.stats.base_daily_reb)} REB`}
                        </div>
                    </div>
                    <div>
                        <div style={styles.miniTitle}>
                            Full Cost
                        </div>
                        <div style={{...styles.miniDescription}}>
                            Based on your country
                        </div>
                        <div style={{...styles.miniTitle}}>
                            {`${Number(this.toNum(this.props.stats.citizen_cost)) + Number(this.applyCountryMultiplier(this.props.stats.country_fee))} REB`}
                        </div>
                    </div>
                </div>
                <div style={{margin: '30px 0 0'}}>
                    <div style={styles.notificationSection}>
                        <Checkbox active={this.state.agree1} updateFn={this.updateAgree.bind(this, 'agree1')} model={'agree1'} field={'agree1'} />
                        <div style={styles.notificationTitle}>
                            I have one of these valid, unexpired, undamaged <span style={{fontWeight: '600', color: 'white'}}>
                                government issued
                            </span> documents (Passport, National ID Card, License) for the country selected above. (Documents such as student IDs, Company IDs, Regional IDs, etc.. will <span style={{fontWeight: '600', color: 'white'}}> NOT </span> be accepted)
                        </div>
                    </div>
                    <div style={{...styles.notificationSection, marginBottom: '20px'}}>
                        <Checkbox active={this.state.agree2} updateFn={this.updateAgree.bind(this, 'agree2')} model={'agree2'} field={'agree2'} />
                        <div style={styles.notificationTitle}>
                            I am <span style={{fontWeight: '600', color: 'white'}}>
                                16 years of age
                            </span> or older
                        </div>
                    </div>
                    <div style={{...styles.notificationSection, marginBottom: '20px'}}>
                        <Checkbox active={this.state.agree3} updateFn={this.updateAgree.bind(this, 'agree3')} model={'agree3'} field={'agree3'} />
                        <div style={styles.notificationTitle}>
                            I have a <span style={{fontWeight: '600', color: 'white'}}>
                                good quality
                            </span> webcam or phone camera capable of taking legible photos of my documents
                        </div>
                    </div>
                    <div style={{...styles.notificationSection, marginBottom: '20px'}}>
                        <Checkbox active={this.state.agree4} updateFn={this.updateAgree.bind(this, 'agree4')} model={'agree4'} field={'agree4'} />
                        <div style={styles.notificationTitle}>
                            I understand that the <span style={{fontWeight: '600', color: 'white'}}>
                                application fee is non-refundable
                            </span> and I could be declined for any reason
                        </div>
                    </div>
                    <div style={{...styles.notificationSection, marginBottom: '20px'}}>
                        <Checkbox active={this.state.agree5} updateFn={this.updateAgree.bind(this, 'agree5')} model={'agree5'} field={'agree5'} />
                        <div style={styles.notificationTitle}>
                            I understand <span style={{fontWeight: '600', color: 'white'}}>
                                will need to pay the country fee
                            </span> of {Number(this.applyCountryMultiplier(this.props.stats.country_fee))} REB following having my identity approved to become a citizen
                        </div>
                    </div>
                    {this.renderPurchase()}
                </div>
            </div>
        )
    }

    updateAgree(attribute) {
        this.setState({[attribute]: !this.state[attribute]})
    }

    renderComplete = () => {
        return (
            <div style={{alignSelf: 'flex-start'}}>
                <div style={styles.modalSection}>
                    <div>
                        <div style={styles.miniTitle}>
                            Verification Status
                        </div>
                        <div style={{...styles.miniDescription}}>
                            You have successfully verified!
                        </div>
                        <div style={{...styles.miniTitle}}>
                            Complete!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderCitizenshipModal = () => {
        if (!this.state.showModal) {
            return null
        }
        return (
            <div style={styles.modalContainer}>
                <div style={styles.container}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-fire" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Verification
                                </span>
                            </div>
                            <div style={styles.description}>
                                {`Verify your identity to become a Rebel Citizen - Step ${this.state.step === 'application' ? 1 : (this.state.step === 'sumsub' ? 2 : 3)}/3`}
                            </div>
                        </div>
                    </div>
                    {this.state.step === 'application' ? this.renderChooseCountry() : null}
                    {this.state.step === 'sumsub' ? this.renderSumsub() : null}
                    {this.state.step === 'countryPayment' ? this.renderPayCountry() : null}
                    {this.state.step === 'complete' ? this.renderComplete() : null}
                </div>
                <div onClick={() => this.closeModal()} style={styles.background} />
            </div>
        )
    }

    renderPayCountry = () => {
        return (
            <div style={{width: '100%'}}>
                <div style={{...styles.modalSection, flexWrap: 'wrap'}}>
                    <div>
                        <div style={styles.miniTitle}>
                            Country Payment
                        </div>
                        <div style={{...styles.miniDescription}}>
                            Pay country fee to finish verification
                        </div>
                        <div>
                            {this.renderPurchase()}
                        </div>
                    </div>
                    <div style={{margin: this.props.appState.mobile ? '40px 0' : '0', width: this.props.appState.mobile ? '100%' : 'auto'}}>
                        <div style={styles.miniTitle}>
                            Daily Income
                        </div>
                        <div style={{...styles.miniDescription}}>
                            Based on your country
                        </div>
                        <div style={{...styles.miniTitle}}>
                            {`${this.applyCountryMultiplier(this.props.stats.base_daily_reb)} REB`}
                        </div>
                    </div>
                    <div>
                        <div style={styles.miniTitle}>
                            Country Cost
                        </div>
                        <div style={{...styles.miniDescription}}>
                            Based on your country
                        </div>
                        <div style={{...styles.miniTitle}}>
                            {`${Number(this.applyCountryMultiplier(this.props.stats.country_fee))} REB`}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBecomeACitizen = () => {
        let costInReb = Number(Web3Utils.fromWei(this.props.stats.citizen_cost, 'ether')) + Number(this.applyCountryMultiplier(this.props.stats.country_fee))
        let costInUSD = (Number(this.props.stats.reb_price) * costInReb).toFixed(2)

        if (this.state.step === 'complete') {
            return null
        }

        return (
            <div style={styles.panel}>
                <div style={styles.header}>
                    <i className="far fa-address-card" style={styles.navIcon} />
                    <span style={styles.headerText}>
                            Become a Rebellion Citizen
                        </span>
                </div>
                <div style={{...styles.description, maxWidth: '800px'}}>
                    Citizenship requires KYC with government documents. KYC is performed to prevent users from obtaining multiple citizenships. Your information is secure and will never be distributed or sold.
                </div>
                <div style={{...styles.dashboardInfo, flexDirection: this.props.appState.mobile ? 'column' : 'row'}}>

                    <div>
                        <div style={styles.miniTitle}>
                            {this.props.appState.appData.current_citizen.citizenship_paid ? 'Get Verified' : `Purchase Citizenship`}
                        </div>
                        <div style={styles.miniDescription}>
                            Complete the verification process
                        </div>
                        <PrimaryButton onClick={() => {this.verify(); this.setState({showModal: true})}} style={{...styles.primaryButton}}>
                            Become A Citizen
                            <span> <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} /> </span>
                        </PrimaryButton>
                    </div>
                    <div style={{...styles.middleInfo, margin: this.props.appState.mobile ? '40px 0' : '0'}}>
                        <div style={styles.miniTitle}>
                            Daily Income
                        </div>
                        <div style={styles.miniDescription}>
                            {`Based on your country`}
                        </div>
                        <div style={{...styles.miniTitle}}>
                            {`${this.applyCountryMultiplier(this.props.stats.base_daily_reb)} REB`}
                        </div>
                    </div>
                    <div>
                        <div style={styles.miniTitle}>
                            Citizenship Cost
                        </div>
                        <div style={styles.miniDescription}>
                            {`${(Number(Web3Utils.fromWei(this.props.stats.citizen_cost, 'ether')) + Number(this.applyCountryMultiplier(this.props.stats.country_fee))).toFixed(2)} REB - ($${costInUSD})`}
                        </div>
                        <a target={"_blank"} href={"https://docs.rebelliondao.com/how-to/how-to-buy-reb"}>
                            <PrimaryButton style={{...styles.primaryButton}}>
                                Buy REB
                                <span>
                                        <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                    </span>
                            </PrimaryButton>
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    render () {

        let costInReb = Number(Web3Utils.fromWei(this.props.stats.citizen_cost, 'ether'))
        let costInUSD = (Number(this.props.stats.reb_price) * costInReb).toFixed(2)

        const isCitizen = this.props.appState.appData.current_citizen.status === "citizen"
        const isSponsored = !!this.props.appState.appData?.current_citizen?.nft_sponsoring

        return (
            <div style={styles.verify}>
                {this.renderBecomeACitizen()}
                <div style={styles.panel}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="far fa-check-circle" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Citizenship Information
                                </span>
                            </div>
                        </div>
                        <div style={{...styles.rebelInfo, display: 'flex', flexDirection: 'column', alignItems: this.props.appState.mobile ? 'flex-start' : 'flex-end'}}>
                            <div style={{...styles.extraInfoText, color: '#999'}}>
                                Referrer
                            </div>
                            <div style={styles.extraInfoText}>
                                {this.props.appState.appData.current_citizen.referred_by ? (this.props.appState.mobile ? (this.props.appState.appData.current_citizen.referred_by.substr(0, 25) + '...') : this.props.appState.appData.current_citizen.referred_by) : "None"}
                            </div>
                        </div>
                    </div>
                    <div style={{...styles.description, maxWidth: '800px'}}>
                        Information regarding your citizenship to the Rebellion DAO. This dashboard also shows your income multiplier and whether or not you have a Rebel NFT delegated to you.
                    </div>
                    <div style={{...styles.dashboardInfo, flexDirection: this.props.appState.mobile ? 'column' : 'row'}}>
                        <div>
                            <div style={styles.miniTitle}>
                                Verification Status
                            </div>
                            <div style={styles.miniDescription}>
                                You are currently
                            </div>
                            <div style={{...styles.miniTitle, color: isCitizen ? '#00b3ff' : '#fc3903'}}>
                                {isCitizen ? `Verified` : 'Not Verified'}
                            </div>
                        </div>
                        <div style={{...styles.middleInfo, margin: this.props.appState.mobile ? '40px 0' : '0', maxWidth: '300px'}}>
                            <div style={styles.miniTitle}>
                                Country
                            </div>
                            <div style={styles.miniDescription}>
                                {`${this.state.countryMultiplier}x multiplier`}
                            </div>
                            <div style={{...styles.miniTitle}}>
                                {this.state.country}
                            </div>
                        </div>
                        <div>
                            <div style={styles.miniTitle}>
                                Sponsorship Status
                            </div>
                            <div style={styles.miniDescription}>
                                Being sponsored gives a multiplier
                            </div>
                            <div style={{...styles.miniTitle, color: isSponsored ? '#00b3ff' : '#fc3903'}}>
                                {isSponsored ? 'Sponsored' : `Not Being Sponsored`}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderCitizenshipModal()}
            </div>
        );
    }
}

export default Verify
