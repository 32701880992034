import React from "react"
import styled from "styled-components";

const styles = {
    banner: {
        padding: '15px',
        fontFamily: 'Lato, Arial',
        fontSize: '20px',
        width: '100%',
        backgroundColor: '#00b3ff',
        fontWeight: '600',
        color: 'white',
        cursor: 'pointer',
    },
    header: {
        padding: '15px',
        fontFamily: 'Lato, Arial',
        fontSize: '20px',
        width: '100%',
        backgroundColor: '#1E2022',
        borderBottom: '1px solid #34373a',
        color: 'white',
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
    },
    innerWindow: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    imageStyle: {
        objectFit: 'cover',
        height: '35px',
    },
    rebellionText: {
        marginLeft: '15px',
        fontWeight: '600',
    },
    daoText: {
        fontSize: '16px',
        marginLeft: '15px',
        fontWeight: '600',
        color: '#00b3ff',
    },
    navButtons: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
    },
    navButton: {
        marginRight: '30px',
    },
    navButtonSecondary: {
        padding: '10px',
        border: '1px solid #FFF',
        borderRadius: '20px',
        cursor: 'pointer',
    },
}

const SecondaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #333 !important;
}
`

let toastId = 0

class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            menuExpanded: false,
            connecting: false,
            toasts: []
        }
    }

    switchToPolygon = async() => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x89' }],
            });
        } catch (e) {
            if (e.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x89',
                                chainName: 'Polygon Mainnet',
                                nativeCurrency: {
                                    name: 'Polygon',
                                    symbol: 'MATIC', // 2-6 characters long
                                    decimals: 18
                                },
                                blockExplorerUrls: ['https://polygonscan.com/'],
                                rpcUrls: ['https://polygon-rpc.com/'],
                            },
                        ],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            }
        }
    }

    addRebToWallet = async() => {
        const tokenAddress = this.props.appState.contractAddresses.token_contract_address;
        const tokenSymbol = 'REB';
        const tokenDecimals = 18;
        const tokenImage = 'https://nanobash.s3.us-west-2.amazonaws.com/fistsquare.png';

        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        image: tokenImage, // A string url of the token logo
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
            console.log(error);
        }
    }

    connectWallet = () => {
        if (!this.state.connecting && window.ethereum) {
            this.setState({connecting: true})
            let test = window.ethereum.request({ method: 'eth_requestAccounts' })
            test.then(res => {
                console.log('res', res)
                if (window.ethereum?.selectedAddress) {
                    this.state.toasts.push({title: 'Wallet Connected', information:  `You have successfully connected your ethereum wallet!`, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                }
            }).catch(err => {
                console.log('err', err)
            })
        }
    }

    logOut = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {}

        fetch(`${window.location.origin}/api/v0/citizens/log_out`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                this.props.signOut()
            })
            .catch(err => {
            })
    }

    renderHamburger() {
        return (
            <div style={{marginLeft: '30px'}} onClick={() => this.props.setMenuExpanded(!this.props.appState.menuExpanded)}>
                <div style={{width: '40px', height: '4px', borderRadius: '5px', backgroundColor: '#DDD'}} />
                <div style={{width: '40px', height: '4px', borderRadius: '5px', backgroundColor: '#DDD', marginTop: '8px'}} />
                <div style={{width: '40px', height: '4px', borderRadius: '5px', backgroundColor: '#DDD', marginTop: '8px'}} />
            </div>
        )
    }

    renderMobile() {
        let needSwitch = !this.props.appState.onPolygon

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {!this.props.appState.hasWindowEthereum && (
                    <a href={'https://metamask.io/download'} target={"_blank"}>
                        <SecondaryButton style={styles.navButtonSecondary}>
                            Metamask
                        </SecondaryButton>
                    </a>
                )}
                {(this.props.appState.hasWindowEthereum && !this.props.appState.connected) &&
                <SecondaryButton onClick={this.connectWallet} style={styles.navButtonSecondary}>
                    Connect
                </SecondaryButton>
                }
                {this.props.appState.connected && this.props.appState.loggedIn &&
                <SecondaryButton onClick={this.logOut} style={styles.navButtonSecondary}>
                    Log Out
                </SecondaryButton>
                }
                {this.renderHamburger()}
            </div>
        )
    }

    renderDesktop() {
        let needSwitch = !this.props.appState.onPolygon

        return (
            <div style={styles.navButtons}>
                {!this.props.appState.hasWindowEthereum && (
                    <a href={'https://metamask.io/download'} target={"_blank"}>
                        <SecondaryButton style={styles.navButtonSecondary}>
                            Get Metamask
                        </SecondaryButton>
                    </a>
                )}
                { this.props.appState.hasWindowEthereum && needSwitch && (
                    <SecondaryButton onClick={this.switchToPolygon} style={{...styles.navButtonSecondary, marginRight: '20px'}}>
                        Switch To Polygon
                    </SecondaryButton>
                )
                }
                { this.props.appState.hasWindowEthereum && !needSwitch && (
                    <SecondaryButton onClick={this.addRebToWallet} style={{...styles.navButtonSecondary, marginRight: '20px'}}>
                        {this.props.appState.mobile ? 'Add REB' : `Add REB To Metamask`}
                    </SecondaryButton>
                )
                }
                {(this.props.appState.hasWindowEthereum && !this.props.appState.connected) &&
                <SecondaryButton onClick={this.connectWallet} style={styles.navButtonSecondary}>
                    Connect Wallet
                </SecondaryButton>
                }
                {this.props.appState.connected && this.props.appState.loggedIn &&
                <SecondaryButton onClick={this.logOut} style={styles.navButtonSecondary}>
                    Log Out
                </SecondaryButton>
                }
            </div>
        )
    }

    render () {

        return (
            <div>
                {/*<div onClick={this.props.setPage.bind(this, 'airdrop')} style={styles.banner}>*/}
                {/*    500 $REB Airdrop per DG Wearable Held (Worth $10+) - CLAIM NOW!*/}
                {/*</div>*/}
                <div style={styles.header}>
                    <div onClick={this.props.setPage.bind(this, '')} style={styles.innerWindow}>
                        <img style={styles.imageStyle} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                        <div>
                            <div style={styles.rebellionText}>
                                {`Rebellion`}
                            </div>
                            <div style={styles.daoText}>
                                {`Base`}
                            </div>
                        </div>
                    </div>
                    {this.props.appState.mobile ? this.renderMobile() : this.renderDesktop()}
                </div>
            </div>
        );
    }
}

export default Header
