import React from "react"
import * as Web3 from "web3-eth";
import Web3Utils from "web3-utils";
import { abi } from '../utils/abi';
import styled from "styled-components";

const styles = {
    sidebar: {
        backgroundColor: '#1E2022',
        // background: 'linear-gradient(146deg, rgba(38,34,33,1) 0%, rgba(46,41,40,1) 19%, rgba(50,44,43,1) 100%)',
        borderRight: '1px solid #34373a',
        height: '100%',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        boxSizing: 'border-box',
        width: '250px',
        fontFamily: 'Lato, Arial',
        color: 'white',
        zIndex: '110',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.5s ease-out 0s',
    },
    userInfo: {
        display: 'inline-flex',
        alignSelf: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
    },
    describer: {
        fontSize: '16px',
        color: '#999',
        marginBottom: '8px',
    },
    username: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '30px',
    },
    balance: {
        fontWeight: '600',
        fontSize: '24px',
        display: 'flex',
    },
    rebIcon: {
        marginLeft: '8px',
        height: '30px',
    },
    rank: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '8px',
    },
    daily: {
        fontWeight: '600',
        fontSize: '14px',
        display: 'flex',
        marginTop: '10px',
        marginBottom: '30px',
        color: '#999',
    },
    rebIconMini: {
        marginLeft: '5px',
        height: '16px',
        marginRight: '5px',
    },
    rebIconMed: {
        marginLeft: '8px',
        height: '16px',
        marginBottom: '-3px',
    },
    navButton: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: '16px',
        padding: '10px',
        borderRadius: '10px',
        marginLeft: '-10px',
        width: '100%',
        cursor: 'pointer',
    },
    navText: {
        width: '100%',
    },
    navButtons: {
        marginTop: '50px',
        width: '100%',
    },
    navButtonsTitle: {
        color: '#999',
        fontWeight: '600',
        fontSize: '16px',
        marginBottom: '10px',
    },
    navIcon: {
        width: '30px',
    },
    navIconRight: {
      justifySelf: 'flex-end',
    },
    rebelImage: {
        width: '100px',
        height: '100px',
        backgroundColor: '#0b0c0d',
        borderRadius: '10px',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 76,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
}

const NavButton = styled.div`
transition: all 0.05s ease 0s;
:hover {
    background-color: #17191a !important;
}
`

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
    }

    isActivePath(path) {
        if (path === 'info' && document.location.pathname === '/' && this.props.appState?.appData?.current_citizen?.status !== "citizen") {
            return true
        }
        if (path === 'dashboard' && document.location.pathname === '/' && this.props.appState?.appData?.current_citizen?.status === "citizen") {
            return true
        }
        return document.location.pathname.substr(1, path.length) === path
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render () {

        // if (this.props.appState.mobile) {
        //     return null
        // }

        let isModerator = this.props.current_citizen?.role === 'admin' || this.props.current_citizen?.role === 'moderator'

        let hasSponsor = !!this.props.appState.appData?.current_citizen?.nft_sponsoring?.thumbnail

        let hasAppData = !!this.props.appState?.appData

        let rank = this.props.appState?.appData?.current_citizen?.rank?.name ?? 'Observer'

        let multiplier = this.props.appState?.appData?.current_citizen?.rank?.multiplier ?? 0

        if (this.props.appState?.appData?.current_citizen?.status !== "citizen") {
            rank = "Observer"
            multiplier = 0
        }

        return (
            <React.Fragment>
                {this.props.appState.menuExpanded && this.props.appState.mobile && (
                    <div onClick={this.props.setMenuExpanded.bind(this, false)} style={styles.background} />
                )}
                <div style={{...styles.sidebar, height: this.props.appState.mobile ? 'calc(100% - 77px)' : '100%', position: this.props.appState.mobile ? 'fixed' : 'relative', left: (this.props.appState.menuExpanded || !this.props.appState.mobile) ? '0px' : '-250px'}}>
                    <div style={styles.userInfo}>
                        <div style={styles.describer}>
                            Rank
                        </div>
                        <div style={styles.rank}>
                            {rank}
                        </div>
                        {hasSponsor &&
                            <div style={styles.rebelImage}>
                                <img width="100px" height="100px" style={{borderRadius: '10px'}} src={`${this.props.appState.appData?.current_citizen?.nft_sponsoring?.thumbnail}`}/>
                            </div>
                        }
                        <div style={styles.daily}>
                            +
                            {Number(Web3Utils.fromWei(this.props.appState?.appData?.current_citizen?.total_income || '0', 'ether')).toFixed(2)}
                            <span>
                                <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                            </span>
                            Daily
                        </div>
                        <div style={styles.describer}>
                            Balance
                        </div>
                        <div style={styles.balance}>
                            {this.numberWithCommas(this.props.appState.balance)}
                            <span>
                                <img style={styles.rebIcon} src={`https://nanobash.s3.us-west-2.amazonaws.com/fist2.png`}/>
                            </span>
                        </div>
                    </div>
                    <div style={styles.navButtons}>
                        <div style={styles.navButtonsTitle}>
                            Nav
                        </div>
                        <NavButton onClick={this.props.setPage.bind(this, 'info')} style={{...styles.navButton, color: this.isActivePath('info') ? '#00b3ff' : 'white'}}>
                            <i className="fas fa-scroll" style={styles.navIcon} />
                            <span style={styles.navText}>
                                Info
                            </span>
                            <i className="fas fa-chevron-right" style={styles.navIconRight} />
                        </NavButton>
                        <NavButton onClick={this.props.setPage.bind(this, 'dashboard')} style={{...styles.navButton, color: this.isActivePath('dashboard') ? '#00b3ff' : 'white'}}>
                            <i className="fas fa-home" style={styles.navIcon} />
                            <span style={styles.navText}>
                                Dashboard
                            </span>
                            <i className="fas fa-chevron-right" style={styles.navIconRight} />
                        </NavButton>
                        <NavButton onClick={this.props.setPage.bind(this, 'mint')} style={{...styles.navButton, color: this.isActivePath('mint') ? '#00b3ff' : 'white'}}>
                            <i className="fas fa-plus-circle" style={styles.navIcon} />
                            <span style={styles.navText}>
                                Mint
                            </span>
                            <i className="fas fa-chevron-right" style={styles.navIconRight} />
                        </NavButton>
                        <NavButton onClick={this.props.setPage.bind(this, 'verify')} style={{...styles.navButton, color: this.isActivePath('verify') ? '#00b3ff' : 'white'}}>
                            <i className="far fa-address-card" style={styles.navIcon} />
                            <span style={styles.navText}>
                                Citizenship
                            </span>
                            <i className="fas fa-chevron-right" style={styles.navIconRight} />
                        </NavButton>
                        <NavButton onClick={this.props.setPage.bind(this, 'rankings')} style={{...styles.navButton, color: this.isActivePath('rankings') ? '#00b3ff' : 'white'}}>
                            <i className="fas fa-crown" style={styles.navIcon} />
                            <span style={styles.navText}>
                                Bounties
                            </span>
                            <i className="fas fa-chevron-right" style={styles.navIconRight} />
                        </NavButton>
                        {/*<NavButton onClick={this.props.setPage.bind(this, 'airdrop')} style={{...styles.navButton, color: this.isActivePath('airdrop') ? '#00b3ff' : 'white'}}>*/}
                        {/*    <i className="fas fa-parachute-box" style={styles.navIcon} />*/}
                        {/*    <span style={styles.navText}>*/}
                        {/*        Airdrop*/}
                        {/*    </span>*/}
                        {/*    <i className="fas fa-chevron-right" style={styles.navIconRight} />*/}
                        {/*</NavButton>*/}
                        {
                            (isModerator) && (

                                <div onClick={this.props.setPage.bind(this, 'admin')} style={{...styles.navButton, color: this.isActivePath('admin') ? '#00b3ff' : 'white'}}>
                                    <i className="fas fa-star" style={styles.navIcon} />
                                    <span style={styles.navText}>
                                        Admin
                                    </span>
                                    <i className="fas fa-chevron-right" style={styles.navIconRight} />
                                </div>
                            )
                        }
                        <div style={{...styles.navButtonsTitle, marginTop: '30px'}}>
                            External Links
                        </div>
                        <a target={"_blank"} href={"https://docs.rebelliondao.com/how-to/how-to-buy-reb"}>
                            <NavButton style={{...styles.navButton, color: this.isActivePath('trade') ? '#00b3ff' : 'white'}}>
                                <i className="fas fa-coins" style={styles.navIcon} />
                                <span style={styles.navText}>
                                    Buy
                                    <span>
                                        <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                    </span>
                                    <i className="fas fa-external-link-alt" style={{...styles.navIcon, marginLeft: '10px'}} />
                                </span>
                                <i className="fas fa-chevron-right" style={styles.navIconRight} />
                            </NavButton>
                        </a>
                        <a target={"_blank"} href={'https://opensea.io/collection/rebellion-rebels'}>
                            <NavButton style={{...styles.navButton}}>
                                <i className="fas fa-store" style={styles.navIcon} />
                                <span style={styles.navText}>
                                    Marketplace
                                    <i className="fas fa-external-link-alt" style={{...styles.navIcon, marginLeft: '10px'}} />
                                </span>
                                <i className="fas fa-chevron-right" style={styles.navIconRight} />
                            </NavButton>
                        </a>
                        <a target={"_blank"} href={'https://docs.rebelliondao.com/'}>
                            <NavButton style={styles.navButton}>
                                <i className="fas fa-book" style={styles.navIcon} />
                                <span style={styles.navText}>
                                    Docs
                                    <i className="fas fa-external-link-alt" style={{...styles.navIcon, marginLeft: '10px'}} />
                                </span>
                                <i className="fas fa-chevron-right" style={styles.navIconRight} />
                            </NavButton>
                        </a>
                        <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                            <NavButton style={styles.navButton}>
                                <i className="fab fa-discord" style={styles.navIcon} />
                                <span style={styles.navText}>
                                    Discord
                                    <i className="fas fa-external-link-alt" style={{...styles.navIcon, marginLeft: '10px'}} />
                                </span>
                                <i className="fas fa-chevron-right" style={styles.navIconRight} />
                            </NavButton>
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Sidebar
