import React from "react"
import Header from "./Header";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import Mint from "./Mint";
import Rankings from "./Rankings";
import Stats from "./Stats";
import Trade from "./Trade";
import Admin from "./Admin";
import Verify from "./Verify";
import * as Web3 from "web3-eth";
import {abi} from "../utils/abi";
import {nftAbi} from "../utils/nftAbi";
import Web3Utils from "web3-utils";
import Welcome from "./Welcome";
import styled from "styled-components";
import Info from "./Info";
import Airdrop from "./Airdrop";

const styles = {
    bodyWrapper: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        overflow: 'hidden',
    },
    appContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
    },
    app: {
        backgroundColor: '#16181A',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
    },
    headerText: {
        marginLeft: '10px',
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px 20px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
}

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

const pages = {
    '': Info,
    'dashboard': Dashboard,
    'mint': Mint,
    'rankings': Rankings,
    'stats': Stats,
    'info': Info,
    'verify': Verify,
    'admin': Admin,
    'airdrop': Airdrop
}

class App extends React.Component {
    constructor(props) {
        super(props)

        const web3Instance = new Web3('https://polygon-rpc.com/')
        console.log("stats", props.stats)

        this.state = {
            web3Instance: web3Instance,
            appDataLoaded: false,
            appData: null,
            page: window.location.pathname.substr(1),
            balance: 0,
            allowance: 0,
            citizen_allowance: 0,
            purchasing_allowance: 0,
            nft_ids: [],
            loading: true,
            nfts: [],
            stats: props.stats,
            citizens: props.citizens,
            connected: false,
            nftsConnected: false,
            loggedIn: !!props.current_citizen,
            enteredDrawing: !!props.entry,
            onPolygon: false,
            hasWindowEthereum: false,
            switchToPolygon: this.switchToPolygon.bind(this),
            mobile: window.innerWidth < 1000,
            menuExpanded: false,
            contractAddresses: props.contract_addresses,
            countryCode: props.country_code,
            countryMultipliers: props.country_multipliers,
            timeToInstantMint: props.time_to_instant_mint,
            countryMultiplier: props.country_multiplier,
            countryCodes: props.country_codes,
            country: props.country,
            randomNumber: 0
        }
    }

    resetUnclaimed = () => {
        this.state.appData.unclaimed_token.wei = "0"
        this.setState({appData: this.state.appData})
    }

    setMenuExpanded = (menuExpanded) => {
        this.setState({menuExpanded: menuExpanded})
    }

    loadAppData = () => {
        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/citizens/app_data`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from load app data', res)
                if (res.success) {
                    this.setState({appData: res.data, appDataLoaded: true, enteredDrawing: !!res.data.entry, loading: false, country: res.data.country, countryMultiplier: res.data.country_multiplier, countryCode: res.data.country_code})
                } else {

                }
            })
            .catch(err => {

            })
    }

    loadNftData = (nft_ids) => {
        const body = {nft_ids}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/nfts/select`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from load nft data', res)
                if (res.success) {
                    this.setState({nfts: res.nfts})
                } else {

                }
            })
            .catch(err => {

            })
    }

    walletConnected = () => {
        this.setState({connected: true})
        this.loadBalance()
        this.loadChain()

        if (this.state.loggedIn) {
            this.loadAppData()
        }
    }

    logIn = () => {
        this.setState({loggedIn: true})

        if (this.state.connected) {
            this.loadAppData()
        }
    }

    loadChain = () => {
        console.log(Web3Utils.hexToNumberString(window.ethereum.chainId))

        if (Web3Utils.hexToNumberString(window.ethereum.chainId) === '137') {
            this.setState({onPolygon: true})
        } else {
            this.setState({onPolygon: false})
        }
    }

    loadAllowance = async() => {
        return new Promise(async(resolve, reject) => {
            let myContract = new this.state.web3Instance.Contract(abi, this.state.contractAddresses.token_contract_address, {gasLimit: "15000000"})
            let allowance = await myContract.methods.allowance(window?.ethereum?.selectedAddress, this.state.contractAddresses.payments_contract_address).call({from: window.ethereum?.selectedAddress});
            allowance = Number(allowance)

            resolve(allowance)
        });
    } // rebel_purchasing_contract_address

    loadPurchasingAllowance = async() => {
        return new Promise(async(resolve, reject) => {
            let myContract = new this.state.web3Instance.Contract(abi, this.state.contractAddresses.token_contract_address, {gasLimit: "15000000"})
            let allowance = await myContract.methods.allowance(window?.ethereum?.selectedAddress, this.state.contractAddresses.rebel_purchasing_contract_address).call({from: window.ethereum?.selectedAddress});
            allowance = Number(allowance)

            resolve(allowance)
        });
    }

    loadCitizenAllowance = async() => {
        return new Promise(async(resolve, reject) => {
            let myContract = new this.state.web3Instance.Contract(abi, this.state.contractAddresses.token_contract_address, {gasLimit: "15000000"})
            let allowance = await myContract.methods.allowance(window?.ethereum?.selectedAddress, this.state.contractAddresses.citizen_payments_contract_address).call({from: window.ethereum?.selectedAddress});
            allowance = Number(allowance)

            resolve(allowance)
        });
    }

    loadNfts = async() => {
        console.log('loading')
        const myPromise = new Promise(async(resolve, reject) => {
            let myContract = new this.state.web3Instance.Contract(nftAbi, this.state.contractAddresses.nft_contract_address, {gasLimit: "15000000"})
            let balance = await myContract.methods.balanceOf(window?.ethereum?.selectedAddress).call({from: window.ethereum?.selectedAddress});
            balance = Number(balance)

            console.log('Nft quantity', balance)

            let ids = []

            for (let i = 0; i < balance; i++) {
                let tokenId = await myContract.methods.tokenOfOwnerByIndex(window?.ethereum?.selectedAddress, i).call({from: window.ethereum?.selectedAddress});
                ids.push(tokenId)
                console.log("Token ID: ", tokenId)
            }

            resolve(ids)
        });
        return myPromise
    }

    setSponsorNft = (nft) => {
        this.state.appData.current_citizen.nft_sponsoring = nft
        this.setState({appData: this.state.appData})
    }

    setNfts = (nfts) => {
        this.state.nfts = nfts
        this.setState({nfts: this.state.nfts})
    }

    setVerificationStatus = (status) => {
        this.loadAppData()
        this.state.appData.current_citizen.status = status
        this.setState({appData: this.state.appData})
    }

    setUpgradePaids = (count) => {
        this.state.appData.upgrade_paids = count
        this.setState({appData: this.state.appData})
    }

    loadBalance = async() => {
        let myContract = new this.state.web3Instance.Contract(abi, this.state.contractAddresses.token_contract_address, { gasLimit: "15000000" })
        let balance = await myContract.methods.balanceOf(window?.ethereum?.selectedAddress).call({from: window.ethereum?.selectedAddress});
        balance = Number(Web3Utils.fromWei(balance, 'ether')).toFixed(2)

        let nft_ids = await this.loadNfts()
        let allowance = await this.loadAllowance()
        let citizen_allowance = await this.loadCitizenAllowance()
        let purchasing_allowance = await this.loadPurchasingAllowance()
        console.log('allowance', allowance)
        this.loadNftData(nft_ids)

        this.setState({balance, nft_ids, allowance, citizen_allowance, purchasing_allowance})
    }

    setAllowance = (allowance) => {
        this.setState({allowance})
    }

    setCitizenAllowance = (citizen_allowance) => {
        this.setState({citizen_allowance})
    }

    setPurchasingAllowance = (purchasing_allowance) => {
        this.setState({purchasing_allowance})
    }

    signOut = () => {
        this.setState({loggedIn: false})
    }

    setEnteredDrawing = (enteredDrawing) => {
        this.setState({enteredDrawing})
    }

    verifySignature(challenge_string, signature) {
        const body = {challenge_string, signature}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/signature_challenges/verify`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from verify', res)
                if (res.success) {
                    this.logIn()
                } else {

                }
            })
            .catch(err => {

            })
    }

    createSignatureChallenge(address) {
        const body = {address}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/signature_challenges`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                console.log('Got response from create', res)
                if (res.success) {
                    const signature = await window.ethereum.request({ method: 'personal_sign', params: [ res.signature_challenge.challenge_string, window.ethereum?.selectedAddress ] });
                    this.verifySignature(res.signature_challenge.challenge_string, signature)
                } else {

                }
            })
            .catch(err => {

            })
    }

    switchToPolygon = async() => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x89' }],
            });
        } catch (e) {
            if (e.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x89',
                                chainName: 'Polygon Mainnet',
                                nativeCurrency: {
                                    name: 'Polygon',
                                    symbol: 'MATIC', // 2-6 characters long
                                    decimals: 18
                                },
                                blockExplorerUrls: ['https://polygonscan.com/'],
                                rpcUrls: ['https://polygon-rpc.com/'],
                            },
                        ],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            }
            // console.error(e)
        }
    }

    renderLoggedOutButton = () => {
        if (!this.state.hasWindowEthereum) {
            return (
                <a href={'https://metamask.io/download'} target={"_blank"}>
                    <PrimaryButton style={{...styles.primaryButton, fontSize: '24px'}}>
                        Download Metamask
                    </PrimaryButton>
                </a>
            )
        }
        else if (this.state.connected) {
             return (
                 <PrimaryButton onClick={this.connectWallet.bind(this)} style={{...styles.primaryButton, fontSize: '24px'}}>
                     Log In
                 </PrimaryButton>
             )
        } else {
             return (
                 <PrimaryButton onClick={this.connectWallet.bind(this)} style={{...styles.primaryButton, fontSize: '24px'}}>
                     Connect Your Wallet
                 </PrimaryButton>
             )
        }

    }

    renderLoggedOut () {
        if (this.state.loading) {
            return (
                <div style={styles.app}>
                </div>
            )
        }
        return (
            <div style={styles.app}>
                <div style={styles.panel}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-power-off" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Enter Rebellion Base
                                </span>
                            </div>
                            <div style={styles.description}>
                                In order to explore you need to connect your wallet & log in
                            </div>
                        </div>
                    </div>
                    {this.renderLoggedOutButton()}
                </div>
            </div>
        )
    }

    connectWallet = () => {
        if (this.state.connected) {
            this.createSignatureChallenge(window.ethereum?.selectedAddress)
        } else if (!this.state.connecting && window.ethereum) {
            this.setState({connecting: true})
            let accountPromise = window.ethereum.request({ method: 'eth_requestAccounts' })
            accountPromise.then(res => {
                console.log('res', res)
                if (window.ethereum?.selectedAddress) {
                    // this.state.toasts.push({title: 'Wallet Connected', information:  `You have successfully connected your ethereum wallet!`, id: toastId++})
                    // this.setState({toasts: this.state.toasts, connected: true})
                    this.walletConnected()
                    if (!this.state.loggedIn) {
                        this.createSignatureChallenge(window.ethereum?.selectedAddress)
                    }
                }
            }).catch(err => {
                console.log('err', err)
            })
        }
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000, menuExpanded: false})
    }

    handleEthereum = () => {
        window?.ethereum?.on('accountsChanged', async (accounts) => {
            // Time to reload your interface with accounts[0]!
            console.log('Account Changed App')
            console.log('accounts', accounts)
            if (accounts[0] === undefined) {
                this.setState({connected: false, loggedIn: false, appData: null, appDataLoaded: false})
            } else {
                this.loadBalance()
                this.setState({connected: true, loggedIn: false, appData: null, appDataLoaded: false})
            }
        })

        window?.ethereum?.on('chainChanged', (networkId) => {
            // Time to reload your interface with the new networkId
            console.log(networkId)
            if (networkId === '0x89') {
                this.setState({onPolygon: true})
            } else {
                this.setState({onPolygon: false})
            }
            console.log('Chain Changed')
        })

        console.log('eth3')
        console.log('we', window.ethereum)
        console.log('sa', window.ethereum.selectedAddress)
        if (window?.ethereum?.selectedAddress) {

            this.loadBalance()
            this.loadChain()

            console.log('connecting')
            this.setState({connected: true})

            if (this.props.current_citizen?.address !== window?.ethereum?.selectedAddress) {
                this.setState({loggedIn: false, loading: false})
            } else {
                this.loadAppData()
            }

        } else {
            this.setState({connected: false, loading: false})
        }

        this.setState({hasWindowEthereum: true})
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
        setInterval(() => this.setState({randomNumber: Math.floor(Math.random() * 10000)}), 1000)
        setInterval(() => this.setState({timeToInstantMint: this.state.timeToInstantMint - 1 < 0 ? 0 : this.state.timeToInstantMint - 1}), 1000)
        if (window.ethereum) {
            setTimeout(() => this.handleEthereum(), 100)
            // this.handleEthereum();
        } else {
            console.log('eth2')
            window.addEventListener('ethereum#initialized', this.handleEthereum, {
                once: true,
            });

            // If the event is not dispatched by the end of the timeout,
            // the user probably doesn't have MetaMask installed.
            setTimeout(() => (!window.ethereum && this.setState({connected: false, loading: false})), 3000); // 3 seconds
        }
    }

    setPage = (page) => {
        window.history.pushState('Object', 'Rebellion DAO', `/${page}`);
        this.setState({page: page})
    }

    render () {
        let CurrentPage = pages[this.state.page]

        if (CurrentPage === undefined) {
            CurrentPage = pages['']
        }

        if (this.state.page === '' && this.state.appData?.current_citizen?.status === "citizen") {
            CurrentPage = pages['dashboard']
        }

        let renderLoggedOut = false
        if (!this.state.connected || !this.state.loggedIn || !this.state.appDataLoaded) {
            renderLoggedOut = true
        }
        if (CurrentPage === Info) {
            renderLoggedOut = false
        }

        if (this.state.loading) {
            renderLoggedOut = true
        }

        return (
            <div style={styles.appContainer}>
                {/*<div>*/}
                {/*    {window?.ethereum?.toString() || 'not found'}*/}
                {/*    {window?.ethereum?.selectedAddress ?? 'no'}*/}
                {/*    {this.state.randomNumber}*/}
                {/*</div>*/}
                <Header signOut={this.signOut.bind(this)} setMenuExpanded={this.setMenuExpanded.bind(this)} appState={this.state} setPage={this.setPage.bind(this)} />
                <div style={styles.bodyWrapper}>
                    <Sidebar nfts={this.state.nfts} setMenuExpanded={this.setMenuExpanded.bind(this)} appState={this.state} {...this.state.appData} setPage={this.setPage.bind(this)} />
                    {renderLoggedOut ? this.renderLoggedOut() : (
                        <CurrentPage
                            {...this.state.appData}
                            appState={this.state}
                            nfts={this.state.nfts}
                            loadNftData={this.loadNftData.bind(this)}
                            setUpgradePaids={this.setUpgradePaids.bind(this)}
                            setVerificationStatus={this.setVerificationStatus.bind(this)}
                            setAllowance={this.setAllowance.bind(this)}
                            setCitizenAllowance={this.setCitizenAllowance.bind(this)}
                            setPurchasingAllowance={this.setPurchasingAllowance.bind(this)}
                            setSponsorNft={this.setSponsorNft.bind(this)}
                            setNfts={this.setNfts.bind(this)}
                            resetUnclaimed={this.resetUnclaimed.bind(this)}
                            setPage={this.setPage.bind(this)}
                            setEnteredDrawing={this.setEnteredDrawing.bind(this)}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default App
