import React from "react"
import Toast from "./Toast";
import styled from "styled-components";
import Web3Utils from "web3-utils";

const styles = {
    rankings: {
        backgroundColor: '#16181A',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        // background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
    },
    ranks: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '10px',
    },
    rank: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '200px',
    },
    rankTitle: {
        color: '#999',
        marginBottom: '10px',
        fontSize: '18px',
    },
    rankText: {
        fontWeight: '600',
        fontSize: '24px',
    },
    dailyReward: {
        fontWeight: '600',
        fontSize: '14px',
        display: 'flex',
        marginTop: '10px',
        color: '#999',
    },
    rebIconMini: {
        marginLeft: '5px',
        height: '16px',
        marginRight: '5px',
    },
    rebIconMed: {
        marginLeft: '8px',
        height: '25px',
    },
    headerText: {
        marginLeft: '10px',
    },
    progressContainerContainer: {
        padding: '0 20px',
        width: '100%',
        boxSizing: 'border-box',
    },
    progressContainer: {
        backgroundColor: '#333',
        position: 'relative',
        width: '100%',
        borderRadius: '20px',
        height: '20px',
        margin: '20px 0',
    },
    progressFilled: {
        backgroundColor: '#00b3ff',
        height: '20px',
        borderRadius: '20px',
    },
    progressText: {
        position: 'absolute',
        color: '#FFF',
        fontWeight: '600',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        textAlign: 'center',
        top: 0,
    },
    pointSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    },
    pointTitle: {
        color: '#999',
        marginBottom: '5px',
        fontSize: '18px',
    },
    points: {
        fontWeight: '600',
        fontSize: '20px',
        marginBottom: '0px',
    },
    taskSection: {
    },
    taskNameSection: {
        fontWeight: '600',
        fontSize: '20px',
        display: 'flex',
        marginBottom: '5px',
    },
    taskName: {
        marginRight: '20px',
    },
    taskPoints: {

    },
    taskDescriptionSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    taskDescription: {
        color: '#999',
        fontSize: '16px',
    },
    taskCTA: {

    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px 30px',
        fontWeight: '600',
        borderRadius: '20px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    table: {
        width: '100%',
        textAlign: 'left',

    },
    row: {
        backgroundColor: '#0b0c0d',
    },
    cell: {
        padding: '8px',
    },
    rewardTable: {
        marginTop: '20px',
    },
    rewardTitle: {
        fontWeight: '600',
        color: '#999',
        fontSize: '16px',
        marginBottom: '20px',
    },
    miniTitle: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    miniDescription: {
        color: '#999',
        fontWeight: '12px',
        marginBottom: '15px',
    },
    referralLink: {
        width: '200px',
        backgroundColor: 'black',
        padding: '10px',
        border: '1px solid #999',
        borderRadius: '10px',
        fontSize: '12px',
        textAlign: 'center',
        outline: 'none',
        color: '#999',
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    container: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '20px',
        width: '800px',
        zIndex: 200,
        fontFamily: 'Lato, Arial',
        background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        color: 'white',
    },
    modalInfo: {
        width: '100%',
    },
    right: {
        flexGrow: 1,
        marginLeft: '20px',
    },
    inputSection: {
        width: '100%',
        marginTop: '20px',
    },
    inputLabel: {
        fontSize: '24px',
        fontWeight: '600',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #333',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '18px',
        borderRadius: '10px',
        padding: '13px 10px 10px 10px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
        outline: 'none',
    },
    buttons: {
        marginTop: '5px',
        width: '100%',
        display: 'flex',
    },
    secondaryButton: {
        padding: '10px',
        display: 'inline-flex',
        border: '1px solid #999',
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        color: '#999',
    },
    disabledButton: {
        backgroundColor: '#5b5b5b',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        cursor: 'not-allowed',
        justifyContent: 'center',
    },
    submissionsSection: {
    },
    submissionContainer: {
        backgroundColor: "#111",
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        marginBottom: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    submissionInfo: {

    },
    submissionTitle: {
        color: '#999',
        marginBottom: '10px',
    },
    submissionButtons: {
        display: 'flex',
        flexDirection: 'column',
    },
    submissionText: {
        fontWeight: '600',
        fontSize: '20px',
    },
    submissionsHeader: {
        fontWeight: '600',
        fontSize: '20px',
        marginBottom: '10px',
    },
    textInfo: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '20px',
        backgroundColor: '#111',
        border: '1px solid #555',
        margin: '20px 0',
        borderRadius: '10px',
    }
}

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

const SecondaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #333 !important;
}
`

let toastId = 1

class Rankings extends React.Component {

    constructor(props) {
        super(props)

        let currentRankId = this.props.appState.appData.current_citizen.rank_id - 1;
        let ranks = this.props.appState.appData.ranks
        let currentRank = ranks[currentRankId]
        let nextRank = ranks[currentRankId + 1]
        if (currentRankId - 1 === ranks.length) {
            nextRank = currentRank
        }

        this.state = {
            toasts: [],
            currentRank: currentRank,
            nextRank: nextRank,
            information: '',
            showModal: false,
            activeSubmission: null,
            submitting: false,
            submissions: []
        }
    }

    componentDidMount() {
        this.loadSubmissions()
    }

    loadSubmissions = () => {
        console.log('loading')
        const body = {submission_text: this.state.information, type: "advocate"}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/rank_awards/search`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                console.log('Got response from rank awards', res)
                if (res.success) {
                    this.setState({submissions: res.rank_awards})
                } else {
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    renderProgressBar = (numerator, denominator) => {
        return (
            <div style={styles.progressContainerContainer}>
                <div style={styles.progressContainer}>
                    <div style={{...styles.progressFilled, width: `${(numerator / denominator) * 100}%`}}>
                    </div>
                    <div style={styles.progressText}>
                        {`${numerator} / ${denominator}`}
                    </div>
                </div>
            </div>
        )
    }

    copy = () => {
        let copyText = document.getElementById("referralLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        this.state.toasts.push({
            title: 'Referral Link Copied',
            information: `You have successfully copied your referral link`,
            id: toastId++
        })
        this.setState({toasts: this.state.toasts})
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    submit = () => {
        console.log('clicked')
        if (this.state.submitting) {
            return
        }

        this.setState({submitting: true})

        const body = {submission_text: this.state.information, type: "advocate"}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/rank_awards`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                console.log('Got response from rank awards', res)
                if (res.success) {
                    this.state.submissions.push(res.rank_award)

                    this.setState({submitting: false, submissions: this.state.submissions, showModal: false, activeSubmission: null})
                } else {
                    this.setState({submitting: false})
                }
            })
            .catch(err => {
                this.setState({submitting: false})
            })
    }

    renderSubmissionModal = () => {
        return (
            <div style={styles.modalContainer}>
                <div style={styles.container}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-fire" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Advocate Points Request
                                </span>
                            </div>
                            <div style={styles.description}>
                                {this.state.activeSubmission.reviewed_at ? `This submission was awarded ${this.state.activeSubmission.points_given} points` : `This submission is currently pending review`}
                            </div>
                        </div>
                    </div>
                    <div style={styles.modalInfo}>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Proof
                            </div>
                            <div style={styles.textInfo}>
                                {this.state.activeSubmission.submission_text}
                            </div>
                            <div style={styles.buttons}>
                                <SecondaryButton onClick={this.closeModal.bind(this)} style={{...styles.secondaryButton}}>
                                    Cancel
                                </SecondaryButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => this.closeModal()} style={styles.background} />
            </div>
        )
    }

    renderModal = () => {

        if (!this.state.showModal) {
            return null
        }

        if (this.state.activeSubmission) {
            return this.renderSubmissionModal()
        }

        return (
            <div style={styles.modalContainer}>
                <div style={styles.container}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-fire" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Advocate Points Request
                                </span>
                            </div>
                            <div style={styles.description}>
                                Please include direct links to your content promoting the Rebellion DAO & add your ETH address in your content / profile. Fraudulent submissions will result in a permanent ban from the Rebellion DAO.
                            </div>
                        </div>
                    </div>
                    <div style={styles.modalInfo}>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Proof
                            </div>
                            <textarea rows={"10"} style={styles.inputBox} placeholder={"Show proof including links to your effort to promote the Rebellion DAO."} name="information" value={this.state.information} onChange={this.onChange.bind(this)}>

                            </textarea>
                            <div style={styles.buttons}>
                                { this.state.submitting && (
                                    <div style={{...styles.disabledButton, marginRight: '20px', fontSize: '20px', padding: '10px 20px'}}>
                                        Submitting
                                    </div>
                                )}
                                { !this.state.submitting && (
                                    <PrimaryButton onClick={this.submit.bind(this)} style={{...styles.primaryButton, marginRight: '20px', fontSize: '20px', padding: '10px 20px'}}>
                                        Submit
                                    </PrimaryButton>
                                )}
                                <SecondaryButton onClick={this.closeModal.bind(this)} style={{...styles.secondaryButton}}>
                                    Cancel
                                </SecondaryButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => this.closeModal()} style={styles.background} />
            </div>
        )
    }

    closeModal() {
        this.setState({showModal: false, activeSubmission: null})
    }

    renderRankProgress = () => {
        if (this.state.nextRank.id === this.state.currentRank.id) {
            return null
        }

        return (
            <div style={styles.panel}>
                <div style={styles.header}>
                    <i className="fas fa-crown" style={styles.navIcon} />
                    <span style={styles.headerText}>
                            Rank Progress
                        </span>
                </div>
                <div style={{...styles.description, maxWidth: '800px'}}>
                    {`Below you can find your current progress to ranking up within the Rebellion DAO. You must achieve ${this.state.nextRank.points_required} points to advance. Each ranking comes with various rewards.`}
                </div>
                {/*<div style={styles.submissionsSection}>*/}
                {/*    {this.renderSubmissions()}*/}
                {/*</div>*/}
                <div style={styles.pointSection}>
                    <div style={styles.points}>
                        Progress To Next Rank
                    </div>
                    {this.renderProgressBar(this.props.appState.appData.current_citizen.points, this.state.nextRank.points_required)}
                </div>
                <div style={{...styles.ranks, flexDirection: this.props.appState.mobile ? 'column' : 'row'}}>
                    <div style={styles.rank}>
                        <div style={styles.rankTitle}>
                            Current Rank
                        </div>
                        <div style={styles.rankText}>
                            {this.state.currentRank.name}
                        </div>
                        <div style={styles.dailyReward}>
                            {this.state.currentRank.multiplier + "x"}
                            <span>
                                    <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                </span>
                            Daily
                        </div>
                    </div>
                    <div style={{...styles.rank, margin: this.props.appState.mobile ? '40px 0' : '0'}}>
                        <div style={styles.rankTitle}>
                            Next Rank
                        </div>
                        <div style={styles.rankText}>
                            {this.state.nextRank.name}
                        </div>
                        <div style={styles.dailyReward}>
                            {this.state.nextRank.multiplier + "x"}
                            <span>
                                    <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                </span>
                            Daily
                        </div>
                    </div>

                    <div style={styles.rank}>
                        <div style={styles.rankTitle}>
                            One Time Rewards
                        </div>
                        <div style={styles.rankText}>
                            {`+${this.numberWithCommas(this.state.nextRank.token_bonus)}`}
                            <span>
                                    <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                </span>
                        </div>
                        <div style={styles.dailyReward}>
                            {`${this.state.nextRank.whitelist_spots} NFT Whitelist spot`}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    renderSubmission = (submission) => {
        return (
            <div style={styles.submissionContainer}>
                <div style={styles.submissionInfo}>
                    <div style={styles.submissionTitle}>
                        Submitted
                    </div>
                    <div style={styles.submissionText}>
                        {submission.submitted_at_in_words}
                    </div>
                </div>
                <div style={styles.submissionInfo}>
                    <div style={styles.submissionTitle}>
                        Status
                    </div>
                    <div style={styles.submissionText}>
                        {submission.reviewed_at ? 'Reviewed' : `Pending Review`}
                    </div>
                </div>
                <div style={styles.submissionInfo}>
                    <div style={styles.submissionTitle}>
                        Points Given
                    </div>
                    <div style={styles.submissionText}>
                        {submission.points_given ?? '-'}
                    </div>
                </div>
                <div style={styles.submissionButtons}>
                    <SecondaryButton onClick={() => this.setState({activeSubmission: submission, showModal: true})} style={{...styles.secondaryButton, fontSize: '14px'}}>
                        View Submission
                    </SecondaryButton>
                </div>
            </div>
        )
    }

    renderSubmissions = () => {
        if (this.state.submissions.length === 0) {
            return null
        }
        return (
            <div>
                <div style={styles.submissionsHeader}>
                    Submissions
                </div>
                {this.state.submissions.map(this.renderSubmission)}
            </div>
        )
    }

    render () {
        return (
            <div style={styles.rankings}>
                {/*<div style={styles.panel}>*/}
                {/*    <div style={styles.header}>*/}
                {/*        <i className="fas fa-crown" style={styles.navIcon} />*/}
                {/*        <span style={styles.headerText}>*/}
                {/*            Weekly Rewards*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div style={styles.description}>*/}
                {/*        The citizens holding the greatest amount of REB each week will receive bonus REB! To increase your supply faster buy REB tokens below!*/}
                {/*    </div>*/}
                {/*    <div style={{...styles.primaryButton, fontSize: '24px'}}>*/}
                {/*        Purchase*/}
                {/*        <span>*/}
                {/*            <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div style={styles.rewardTable}>*/}
                {/*        <div style={styles.rewardTitle}>*/}
                {/*            Weekly Rewards*/}
                {/*        </div>*/}
                {/*        <table style={styles.table}>*/}
                {/*            <tr>*/}
                {/*                <th style={styles.cell}># Winners</th>*/}
                {/*                <th style={styles.cell}>REB Reward</th>*/}
                {/*                <th style={styles.cell}>REB Cutoff</th>*/}
                {/*            </tr>*/}
                {/*            <tr style={styles.row}>*/}
                {/*                <td style={styles.cell}>*/}
                {/*                    Top 10 Holders*/}
                {/*                </td>*/}
                {/*                <td style={styles.cell}>*/}
                {/*                    10,000*/}
                {/*                    <span>*/}
                {/*                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}
                {/*                    </span>*/}
                {/*                </td>*/}
                {/*                <td style={styles.cell}>325,032*/}
                {/*                    <span>*/}
                {/*                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}
                {/*                    </span></td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <td style={styles.cell}>*/}
                {/*                    Next 100 Holders</td>*/}
                {/*                <td style={styles.cell}>*/}
                {/*                    5,000*/}
                {/*                    <span>*/}
                {/*                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}
                {/*                    </span>*/}
                {/*                </td>*/}
                {/*                <td style={styles.cell}>35,032*/}
                {/*                    <span>*/}
                {/*                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}
                {/*                    </span></td>*/}
                {/*            </tr>*/}
                {/*            <tr style={styles.row}>*/}
                {/*                <td style={styles.cell}>*/}
                {/*                    Next 1000 Holders*/}
                {/*                </td>*/}
                {/*                <td style={styles.cell}>*/}
                {/*                    100*/}
                {/*                    <span>*/}
                {/*                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}
                {/*                    </span>*/}
                {/*                </td>*/}
                {/*                <td style={styles.cell}>*/}
                {/*                    25,032*/}
                {/*                    <span>*/}
                {/*                        <img style={styles.rebIconMini} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />*/}
                {/*                    </span>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*        </table>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {this.renderRankProgress()}
                <div style={styles.panel}>
                    <div style={styles.taskSection}>
                        <div>
                            <div style={styles.miniTitle}>
                                {`Referral Points - ${this.props.referral_points}`}
                            </div>
                            <div style={styles.miniDescription}>
                                {`For each successful referral made you will receive 25% of the country fee in REB tokens and 1 referral point. For a referral to count the user must use your referral link AND become a verified Rebellion Citizen.`}
                            </div>
                            <div style={{width: '100%', display: 'flex'}}>
                                <input id={"referralLink"} style={styles.referralLink} value={`https://rebellionbase.com/?referred_by=${this.props.appState.appData.current_citizen.address}`} />
                                <div style={{marginLeft: '20px'}}>
                                    <PrimaryButton onClick={this.copy.bind(this)} style={{...styles.primaryButton, padding: '10px 20px'}}>
                                        Copy
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div style={styles.taskSection}>*/}
                    {/*    <div style={styles.miniTitle}>*/}
                    {/*        {`Advocate Points - ${this.props.advocate_points}`}*/}
                    {/*    </div>*/}
                    {/*    <div style={styles.taskDescriptionSection}>*/}
                    {/*        <div style={styles.taskDescription}>*/}
                    {/*            Advocate points are achieved by promoting the Rebellion DAO. Each point earned will grant 10 additional REB tokens. Please note that point requirements are expected to rise as the DAO grows and will be updated weekly. Anyone found trying to defraud the system will be permanently banned from the Rebellion DAO and no longer receive any income.*/}
                    {/*            <ul style={{color: 'white', margin: '20px 0'}}>*/}
                    {/*                <li>*/}
                    {/*                    <span style={{fontWeight: 600}}>*/}
                    {/*                         Creating Youtube Videos*/}
                    {/*                    </span>*/}
                    {/*                    : Currently ~1 point per 1,000 views*/}
                    {/*                </li>*/}
                    {/*                <li style={{margin: '20px 0'}}>*/}
                    {/*                    <span style={{fontWeight: 600}}>*/}
                    {/*                         Writing Comments / Posts / Articles*/}
                    {/*                    </span>*/}
                    {/*                     : Currently ~1 point per 10 likes*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <span style={{fontWeight: 600}}>*/}
                    {/*                         Twitter / Instagram / Facebook Posts*/}
                    {/*                    </span>*/}
                    {/*                    : Currently ~1 point per 1000 followers*/}
                    {/*                </li>*/}
                    {/*                <li style={{margin: '20px 0 0'}}>*/}
                    {/*                    <span style={{fontWeight: 600}}>*/}
                    {/*                         Other - Anything that helps the Rebellion DAO*/}
                    {/*                    </span>*/}
                    {/*                    : Submit anything! Useful contributions receive points!*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*        <div style={styles.taskCTA}>*/}
                    {/*            <PrimaryButton onClick={() => this.setState({showModal: true})} style={styles.primaryButton}>*/}
                    {/*                Submit Proof*/}
                    {/*            </PrimaryButton>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div style={styles.panel}>
                    <div style={styles.taskSection}>
                        <div style={styles.miniTitle}>
                            {`Holder Points - ${this.props.appState.appData.current_citizen.holder_points || 0}`}
                        </div>
                        <div style={styles.taskDescriptionSection}>
                            <div style={styles.taskDescription}>
                                For every 10,000 REB held you will receive 1 Holder Point per week (Snapshots taken randomly).
                            </div>
                            <div style={{...styles.taskCTA, marginTop: '20px'}}>
                                <a target={"_blank"} href={"https://docs.rebelliondao.com/how-to/how-to-buy-reb"}>
                                    <PrimaryButton style={styles.primaryButton}>
                                        Purchase
                                        <span>
                                            <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                        </span>
                                    </PrimaryButton>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={styles.panel}>
                    <div style={{...styles.taskSection, marginBottom: 0}}>
                        <div style={styles.miniTitle}>
                            Request Sponsorship
                        </div>
                        <div style={styles.taskDescriptionSection}>
                            <div style={styles.taskDescription}>
                                If you would like an upfront reward before making a piece of content please use the #bounties channel in the official discord.
                            </div>
                            <div style={{...styles.taskCTA, marginTop: '20px'}}>
                                <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                                    <PrimaryButton style={styles.primaryButton}>
                                        Make a Request
                                    </PrimaryButton>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderToasts()}
                {this.renderModal()}
            </div>
        );
    }
}

export default Rankings
