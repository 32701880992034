import React from "react"
import { abi } from '../utils/abi';
import * as Web3 from "web3-eth";
import Web3Utils from "web3-utils";
import styled from "styled-components";
import Toast from "./Toast";

const styles = {
    admin: {
        backgroundColor: '#16181A',
        // background: 'linear-gradient(146deg, rgba(13,11,11,1) 0%, rgba(14,12,12,1) 19%, rgba(6,5,5,1) 100%)',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        MsOverflowStyle: 'none',
        color: 'white',
        padding: '50px',
        alignItems: 'center',
    },
    panel: {
        display: 'flex',
        padding: '20px',
        borderRadius: '20px',
        // background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        backgroundColor: '#1e2022',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px',
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        margin: '25px',
    },
    header: {
        fontWeight: '600',
        fontSize: '30px',
    },
    description: {
        color: '#999',
        marginBottom: '30px',
        marginTop: '10px',
        textAlign: 'center',
    },
    headerText: {
        marginLeft: '10px',
    },
    dashboardInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '15px',
    },
    middleInfo: {
        margin: '0 50px',
        minWidth: '200px',
    },
    miniTitle: {
        color: '#999',
        fontWeight: '600',
        fontSize: '14px',
        marginBottom: '8px',
    },
    stat: {
        fontWeight: '600',
        fontSize: '24px',
        marginBottom: '4px',
    },
    countdown: {
        fontWeight: '600',
        fontSize: '30px',
        marginBottom: '4px',
    },
    miniDescription: {
        color: '#999',
        fontWeight: '12px',
        marginBottom: '15px',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px',
        fontWeight: '600',
        cursor: 'pointer',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
    },
    submissionsSection: {
    },
    submissionContainer: {
        padding: '20px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        marginBottom: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: "#111",
        borderRadius: '10px',
    },
    submissionInfo: {

    },
    submissionTitle: {
        color: '#999',
        marginBottom: '10px',
    },
    submissionButtons: {
        display: 'flex',
        alignItems: 'center',
    },
    submissionText: {
        fontWeight: '600',
        fontSize: '20px',
    },
    submissionsHeader: {
        fontWeight: '600',
        fontSize: '20px',
        marginBottom: '10px',
    },
    secondaryButton: {
        padding: '10px',
        display: 'inline-flex',
        border: '1px solid #999',
        borderRadius: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
        color: '#999',
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    container: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '20px',
        width: '800px',
        zIndex: 200,
        fontFamily: 'Lato, Arial',
        background: 'linear-gradient(350deg, rgba(29,32,33,1) 0%, rgba(29,32,33,1) 19%, rgba(20,22,23,1) 100%)',
        color: 'white',
    },
    modalInfo: {
        width: '100%',
    },
    right: {
        flexGrow: 1,
        marginLeft: '20px',
    },
    inputSection: {
        width: '100%',
        marginTop: '20px',
    },
    inputLabel: {
        fontSize: '24px',
        fontWeight: '600',
        margin: '5px 0'
    },
    inputLabelNew: {
        fontSize: '24px',
        fontWeight: '600',
        margin: '25px 0 5px'
    },
    inputBox: {
        border: '1px solid #333',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '18px',
        borderRadius: '10px',
        padding: '11px 10px 10px 10px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
        outline: 'none',
    },
    buttons: {
        marginTop: '5px',
        width: '100%',
        display: 'flex',
    },
    disabledButton: {
        backgroundColor: '#5b5b5b',
        padding: '10px',
        fontWeight: '600',
        borderRadius: '12px',
        display: 'flex',
        cursor: 'not-allowed',
        justifyContent: 'center',
    },
    textInfo: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '20px',
        backgroundColor: '#111',
        border: '1px solid #555',
        margin: '20px 0',
        borderRadius: '10px',
    }
}

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

const SecondaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #333 !important;
}
`

let toastId = 1

class Admin extends React.Component {
    constructor(props) {
        super(props)

        const web3Instance = new Web3('https://polygon-rpc.com/')

        this.state = {
            totalSupply: 0,
            treasurySupply: 0,
            rank_awards: [],
            toasts: [],
            showModal: false,
            points: "",
            activeSubmission: null,
            citizen: {},
            web3Instance: web3Instance,
            whitelist_spots: "",
            balance: 0
        }
    }

    fetchRankAwards = async() => {
        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/rank_awards`, {
            method: "GET",
            headers: headers
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({rank_awards: res.rank_awards})
                } else {

                }
            })
            .catch(err => {

            })
    }

    componentDidMount = async() => {
        await this.fetchRankAwards()
    }

    renderSubmission = (submission) => {
        return (
            <div style={styles.submissionContainer}>
                <div style={styles.submissionInfo}>
                    <div style={styles.submissionTitle}>
                        Submitted
                    </div>
                    <div style={styles.submissionText}>
                        {submission.submitted_at_in_words}
                    </div>
                </div>
                <div style={styles.submissionInfo}>
                    <div style={styles.submissionTitle}>
                        Status
                    </div>
                    <div style={styles.submissionText}>
                        Pending Review
                    </div>
                </div>
                <div style={styles.submissionInfo}>
                    <div style={styles.submissionTitle}>
                        Points Given
                    </div>
                    <div style={styles.submissionText}>
                        -
                    </div>
                </div>
                <div style={styles.submissionButtons}>
                    <SecondaryButton onClick={() => this.setState({showModal: true, activeSubmission: submission})} style={{...styles.secondaryButton, fontSize: '14px'}}>
                        View Submission
                    </SecondaryButton>
                </div>
            </div>
        )
    }

    renderSubmissions = () => {
        if (this.state.rank_awards.length === 0) {
            return null
        }
        return (
            <div>
                <div style={styles.submissionsHeader}>
                    Submissions
                </div>
                {this.state.rank_awards.map(this.renderSubmission)}
            </div>
        )
    }

    submit = () => {
        const body = {whitelist_spots: this.state.whitelist_spots}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        if (this.state.citizen?.address) {
            this.setState({submitting: true})
            fetch(`${window.location.origin}/api/v0/citizens/${this.state.citizen.address}`, {
                method: "PATCH",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        this.state.toasts.push({
                            title: 'Citizen updated',
                            information: `You have successfully updated the citizen`,
                            id: toastId++
                        })
                        this.setState({toasts: this.state.toasts, submitting: false})
                    } else {

                    }
                })
                .catch(err => {

                })
        }
    }

    fetchAccount = (address) => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/citizens/${address}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from submit', res)
                if (res.success) {
                    this.loadBalance(res.citizen.address)
                    this.setState({citizen: res.citizen, whitelist_spots: res.citizen?.whitelist_spots})
                } else {
                    this.setState({citizen: {}})
                }
            })
            .catch(err => {

            })
    }

    loadBalance = async(address) => {
        let myContract = new this.state.web3Instance.Contract(abi, this.props.appState.contractAddresses.token_contract_address, { gasLimit: "15000000" })
        let balance = await myContract.methods.balanceOf(address).call({from: window.ethereum?.selectedAddress});
        balance = Number(Web3Utils.fromWei(balance, 'ether')).toFixed(2)

        this.setState({balance})
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})

        console.log(e.target.name)
        if (e.target.name === "address") {
            if (e.target.value.length === 42) {
                this.fetchAccount(e.target.value)
            }
        }
    }

    renderSubmissionModal = () => {

        if (!this.state.showModal) {
            return null
        }

        return (
            <div style={styles.modalContainer}>
                <div style={styles.container}>
                    <div style={styles.headerContainer}>
                        <div>
                            <div style={styles.header}>
                                <i className="fas fa-fire" style={styles.navIcon} />
                                <span style={styles.headerText}>
                                    Advocate Points Request
                                </span>
                            </div>
                            <div style={styles.description}>
                                Determine the amount of points that should be rewarded
                            </div>
                        </div>
                    </div>
                    <div style={styles.modalInfo}>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Proof
                            </div>
                            <div style={styles.textInfo}>
                                {this.state.activeSubmission.submission_text}
                            </div>
                            <input style={{...styles.inputBox, marginBottom: '20px'}} placeholder={"Points to give"} name="points" value={this.state.points} onChange={this.onChange.bind(this)}>

                            </input>
                            <div style={styles.buttons}>
                                { this.state.submitting && (
                                    <div style={{...styles.disabledButton, marginRight: '20px', fontSize: '20px', padding: '10px 20px'}}>
                                        Submitting
                                    </div>
                                )}
                                { !this.state.submitting && (
                                    <PrimaryButton onClick={this.submit.bind(this)} style={{...styles.primaryButton, marginRight: '20px', fontSize: '20px', padding: '10px 20px'}}>
                                        Submit
                                    </PrimaryButton>
                                )}
                                <SecondaryButton onClick={this.closeModal.bind(this)} style={{...styles.secondaryButton}}>
                                    Cancel
                                </SecondaryButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => this.closeModal()} style={styles.background} />
            </div>
        )
    }

    closeModal() {
        this.setState({showModal: false})
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderSearch = () => {
        return (
            <div>
                <input style={{...styles.inputBox, marginBottom: '20px'}} placeholder={"Address"} name="address" value={this.state.address} onChange={this.onChange.bind(this)} />
                <div style={styles.inputLabelNew}>
                    Citizen Address
                </div>
                <div>
                    {this.state.citizen?.address}
                </div>
                <div style={styles.inputLabelNew}>
                    Citizenship Status
                </div>
                <div>
                    {this.state.citizen?.status}
                </div>
                <div style={styles.inputLabelNew}>
                    Points
                </div>
                <div>
                    {this.state.citizen?.points}
                </div>
                <div style={styles.inputLabelNew}>
                    Balance
                </div>
                <div>
                    {this.state.balance}
                </div>
                <div style={styles.inputLabelNew}>
                    Whitelist Spots
                </div>
                <input style={{...styles.inputBox, marginBottom: '20px'}} placeholder={"Whitelist Spots"} name="whitelist_spots" value={this.state.whitelist_spots} onChange={this.onChange.bind(this)} />
                {this.renderSubmitButton()}
            </div>
        )
    }

    renderSubmitButton = () => {
        if (this.state.submitting) {
            return (
                <div style={{...styles.disabledButton, marginRight: '20px', fontSize: '20px', padding: '10px 20px'}}>
                    Updating
                </div>
            )
        }

        if (!this.state.citizen) {
            return (
                <div style={{...styles.disabledButton, marginRight: '20px', fontSize: '20px', padding: '10px 20px'}}>
                    Update
                </div>
            )
        }

        return (
            <PrimaryButton onClick={this.submit.bind(this)} style={{...styles.primaryButton, marginRight: '20px', fontSize: '20px', padding: '10px 20px'}}>
                Update
            </PrimaryButton>
        )
    }

    render () {
        return (
            <div style={styles.admin}>
                <div style={styles.panel}>
                    <div style={styles.header}>
                        <i className="fas fa-star" style={styles.navIcon} />
                        <span style={styles.headerText}>
                            Admin
                        </span>
                    </div>
                    <div style={{...styles.description, maxWidth: '800px'}}>
                        Control Panel to manage users
                    </div>
                    {/*{this.renderSubmissions()}*/}
                    {this.renderSearch()}
                    {this.renderSubmissionModal()}
                </div>
                {this.renderToasts()}
            </div>
        );
    }
}

export default Admin
